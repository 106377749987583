<template>
	<div class="p-4">
		<p class="text-2xl">Cargos</p>
		<div class="flex flex-row space-x-4">
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Cargos actuales</p>
				{{ currentPedidos.length }}	
			</div>

			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Faltantes</p>
				{{ faltantes.length }}	
			</div>

			
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Nuevos</p>
				{{ currentPedidos.filter( p => p.new ).length }}	
			</div>
			

			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Pausados</p>
				{{ pausados.length }}	
			</div>
		</div>
		<div class="flex flex-row space-x-4">
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Cargos pasados</p>
				{{ previousPedidos.length  }}	
			</div>
			
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Cargos estimados</p>
				{{ faltantes.length + currentPedidos.length }}	
			</div>

			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Cancelados</p>
				{{ cancelados.length }}	
			</div>

			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Fallidos</p>
				{{ previousPedidos.filter( pedido => pedido.faltante_tipo == "fallido" ).length }}	
			</div>			
		</div>
		<div class="flex flex-row space-x-4">
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Crecimiento</p>
				{{ faltantes.length + currentPedidos.length - previousPedidos.length }}	({{ previousPedidos.length == 0 ? "" : Math.round(((faltantes.length + currentPedidos.length - previousPedidos.length)/previousPedidos.length)*10000)/100 }}%)
			</div>
			<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
				<p class="text-xl">Churn</p>
				{{ [...new Set(futureFaltantes.concat(futureFallidos))].length }}	({{ previousPedidos.length == 0 ? "" : Math.round((([...new Set(futureFaltantes.concat(futureFallidos))].length)/previousPedidos.length)*10000)/100 }}%)
			</div>		
		</div>
		<div class="flex space-x-4">
			<div class="pt-4 w-full">       
				<select v-model="period" @change="getPedidosForPeriod(period)" class="select select-sm select-bordered w-full">	
					<option v-for="period in periods" v-bind:key="`${period.month}${period.year}`" :value="period"> {{period.month}}/{{period.year}} </option>
				</select>
			</div>
		</div>		


		<div class="flex space-x-4">
			<div class="pt-4 w-full">       
				<select v-model="filter" class="select select-sm select-bordered w-full">	
					<option value=""> Todos </option>
					<option value="faltante"> Faltantes </option>
					<option value="cancelado"> Cancelado </option>
					<option value="pausado"> Pausado </option>
					<option value="fallido"> Fallido </option>
					<option value="otro"> Otro </option>
				</select>
			</div>
		</div>		

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-2/12">Día</th>								
						<th class="w-1/12">Correo</th>															
						<th class="w-1/12">Club</th>															
						<th class="w-2/12">Faltante</th>		
						<th class="w-2/12">Tipo</th>
						<th class="w-2/12">Notas</th>
						<th class="w-1/12">Accion</th>
					</tr>
				</thead>
				<tbody v-for="pedido in previousPedidosFiltrados" v-bind:key="pedido.id">
					<tr>
						<td>
							<router-link :to="`/pedido/${pedido.id}`">{{pedido.created_at.split("T")[0].split('-')[2]}}</router-link>								
						</td>								
						<td>
							<div class="flex space-x-2 items-center">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 cursor-pointer" @click="copyToClipboard(pedido.cliente.correo)">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
								</svg>

								<router-link :to="`/cliente/${pedido.cliente.id}`">{{pedido.cliente ? pedido.cliente.correo : ""}}</router-link>
							</div>
						</td>							
						<td>{{ pedido.cliente.suscripciones[0].estatus}}</td>		
						<td>{{ pedido.exists == true ? "No" : "Si"}}</td>		
						<td>
							<p v-if="!edit[pedido.id]">{{pedido.faltante_tipo}}</p>								
							<select v-if="edit[pedido.id]" v-model="pedido.faltante_tipo" className="select select-sm select-block select-bordered w-full">		
								<option value="cancelado"> Cancelado </option>
								<option value="pausado"> Pausado </option>
								<option value="fallido"> Fallido </option>
								<option value="otro"> Otro </option>	
								<option value=""> Recuperado </option>	
							</select>		
						</td>
						<td>
							<p v-if="!edit[pedido.id]">{{pedido.faltante_notas}}</p>
							<input v-if="edit[pedido.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="pedido.faltante_notas"/>
						</td>		
						<td>
							<div class="flex flex-row space-x-2 w-full">
								<button v-if="pedido.faltante_tipo == 'fallido'" @click="mandarMensaje(pedido.cliente)" class="btn btn-primary btn-sm no-animation w-full flex-1">Mensaje</button>
								<button v-if="!edit[pedido.id]" @click="edit[pedido.id] = true" class="btn btn-primary btn-sm no-animation w-full flex-1">Editar</button>
								<button v-if="edit[pedido.id]"  @click="updatePedido(pedido)" class="btn btn-primary btn-sm no-animation w-full flex-1">Guardar</button>
								<div v-if="edit[pedido.id]" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="cancelEdit(pedido);">
									<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 w-8">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
									</svg>
								</div>
							</div>					
						</td>												
					</tr>					
				</tbody>
			</table>
		</div>	
	</div>
</template>
<script>
	import { supabase } from "../supabase"
	import utils from "../utils"
	import { onMounted, ref, computed } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminPedidos',
		setup() {	
			const periods = ref([])
			const period = ref({})
			const previousPedidos = ref([])									
			const currentPedidos = ref([])		
			let edit = ref({})				

			const filter = ref("")			

			const loading = useLoading({
				loader: "spinner"
			});

			async function getPedidos(start, end) {	
				console.log(start, end)
				const productIds = [54727, 54728, 54729, 54730, 54731, 54732]; 

				const { data } = await supabase
				.from('pedidos')
				.select('*, cliente(id, nombre, apellido, correo, country_code, telefono, direccion, suscripciones(*)), pedido_detalles!inner(producto!inner(*)))')
				.in('pedido_detalles.producto.id', productIds)	
				.order('created_at', { ascending: true })
				.gte('created_at', start.toISOString())
				.lte('created_at', end.toISOString())
				.eq('cancelado', false)					

				data.map( p => {
					let date = new Date(p.created_at);
					date.setHours(date.getHours() - 6)
					p.created_at = date.toISOString()										
					return p
				})

				if (data) {					
					return data
				} else {
					alert ("No data returned")
				}
			}					

			const previousPedidosFiltrados = computed( () => {
				return previousPedidos.value.filter( p => {
					// let currentDay = (new Date()).getDate()
					// let pedidoDate = new Date(p.created_at)
					// pedidoDate.setHours(pedidoDate.getHours() + 6)
					// let pedidoDay = pedidoDate.getDate()					

					switch (filter.value) {
					case "cancelado":
						return p.faltante_tipo == "cancelado"  || p.cliente.suscripciones[0].estatus == 'cancelado'
					case "pausado":
						return p.faltante_tipo == "pausado" || p.cliente.suscripciones[0].estatus == 'pausado'
					case "fallido":
						return p.faltante_tipo == "fallido"
					case "otro":
						return p.faltante_tipo == "otro"
					case "faltante":
						return !p.exists && p.cliente.suscripciones[0].estatus == "activo" && !p.faltante_tipo //pedidoDay <= currentDay && p.exists == false //&& p.cliente.suscripciones[0].estatus == "activo"
					default:
						return true						
					}

				})
			})

				//There are charges that are included in the churn that were charged in the current month, but were cancelled or paused after the fact. That is why the math doesn't always make sense. 

			const faltantes = computed ( () => {
				return previousPedidos.value.filter( p => {					
					return !p.exists && p.cliente.suscripciones[0].estatus == "activo" && !p.faltante_tipo
				}).map( p => p.cliente.id )
			})			

			const cancelados = computed ( () => {
				return previousPedidos.value.filter( pedido => pedido.faltante_tipo == "cancelado" || pedido.cliente.suscripciones[0].estatus == 'cancelado' ).filter( p => !p.exists ).map( p => p.cliente.id )
			})

			const pausados = computed ( () => {
				return previousPedidos.value.filter( pedido => pedido.faltante_tipo == "pausado" || pedido.cliente.suscripciones[0].estatus == 'pausado').filter( p => !p.exists ).map( p => p.cliente.id )						
			})					

			const futureFallidos = computed( () => {
				return previousPedidos.value.filter(pedido => ["cancelado", "pausado", "fallido"].includes(pedido.faltante_tipo)).filter( p => !p.exists ).map( p => p.id )
			})

			const futureFaltantes = computed( () => {
				return previousPedidos.value.filter(pedido => ["cancelado", "pausado"].includes(pedido.cliente.suscripciones[0].estatus)).filter( p => !p.exists ).map( p => p.id )
			})

			onMounted( async () => {		
				let loader = loading.show()		
				const d = new Date();		

				periods.value = getLastMonths(d.getMonth()+1, d.getFullYear()); 
				period.value = periods.value[0]

				await getPedidosForPeriod(period.value)
				loader.hide()
			})				

			async function getPedidosForPeriod(period) {
				console.log("period", period)

				const { previousMonth, currentMonth } = getMonthDates(period.month, period.year);

				console.log(previousMonth, currentMonth)

				const [previous, current] = await Promise.all([
					getPedidos(previousMonth.startDate, previousMonth.endDate), 
					getPedidos(currentMonth.startDate, currentMonth.endDate)
					]);

				console.log(previous, current)

				previousPedidos.value = previous.map( p => {
					p.copy = structuredClone(p)
					return p
				})						

				currentPedidos.value = current.map( p => {
					p.copy = structuredClone(p)
					return p
				})

				checkIfObjectsExist(previousPedidos.value, currentPedidos.value, 'cliente');	
			}

			function checkIfObjectsExist(array1, array2, key) {
				array1.forEach(obj1 => {
					obj1.exists = array2.some(obj2 => obj2[key].id === obj1[key].id);	
				});

				array2.forEach(obj1 => {
					obj1.new = !array1.some(obj2 => obj2[key].id === obj1[key].id);	
				});
				return array1;
			}

			function getMonthDates(month, year) {
				console.log("getMonthDates", month, year)

				let currentMonthStart = new Date(year, month - 1, 1);
				// currentMonthStart.setHours(currentMonthStart.getHours() + 6)

				console.log("currentMonthStart", currentMonthStart.toISOString())

				let currentMonthEnd = new Date(year, month, 0);			
				currentMonthEnd.setHours(currentMonthEnd.getHours() + 24)

				console.log("currentMonthEnd", currentMonthEnd.toISOString())

				const previousMonth = month === 1 ? 12 : month - 1;
				const previousYear = month === 1 ? year - 1 : year;

				let previousMonthStart = new Date(previousYear, previousMonth - 1, 1);					
				// previousMonthStart.setHours(previousMonthStart.getHours() + 6)		

				console.log("previousMonthStart", previousMonthStart.toISOString())

				let previousMonthEnd = new Date(previousYear, previousMonth, 0);
				previousMonthEnd.setHours(previousMonthEnd.getHours() + 24)

				console.log("previousMonthEnd", previousMonthEnd.toISOString())

				return {
					currentMonth: {
						startDate: currentMonthStart,
						endDate: currentMonthEnd
					},
					previousMonth: {
						startDate: previousMonthStart,
						endDate: previousMonthEnd
					}
				}
			}

			function getLastMonths(month, year) {
				const months = [];

				for (let i = 0; i < 2; i++) {
					months.push({ month, year });

					month--;
					if (month === 0) {
						month = 12;
						year--;
					}
				}

				return months;
			}

			async function updatePedido(pedido) {
				if(!confirm("¿Quieres guardar tus cambios?")){
					return cancelEdit(pedido)
				}		

				let data = {
					faltante_tipo: pedido.faltante_tipo == "" ? null : pedido.faltante_tipo,
					faltante_notas: pedido.faltante_notas
				}

				const { error } = await supabase.from('pedidos').update(data).eq('id', pedido.id);	

				if (error) {
					console.log(error)
					return alert (error.details)
				}

				replaceObjectInArray(previousPedidos.value, pedido)

				useToast().success("Prospecto actualizada", { position: "top-right" });	

				edit.value[pedido.id] = false
			}

			function cancelEdit(pedido) {
				edit.value[pedido.id] = false			

				console.log(pedido)
				replaceObjectInArray(previousPedidos.value, pedido.copy)
			}

			function replaceObjectInArray(array, newObject) {
				const index = array.findIndex(obj => obj.id === newObject.id);

				console.log()
				if (index !== -1) {
					array[index] = newObject;
					array[index].copy = structuredClone(newObject)
				}

				return array;
			}

			function copyToClipboard(text) {
				navigator.clipboard.writeText(text)
				.then(() => {
					useToast().success("Correo copiado", { position: "top-right" });	
				})
				.catch(err => {
					console.error('Error in copying text: ', err);
				});
			}

			async function mandarMensaje(cliente) {
				let mensaje = {
					template: 'cargo_fallido',
					nombre: 'Cargo fallido'
				}

				let params = [{
					name: "nombre",
					value: cliente.nombre
				}]

				if(!confirm("¿Quieres mandar el mensaje?")){
					return 
				}	

				let loader = loading.show()					

				let statusMandar = await utils.mandarMensaje(cliente, mensaje, params)

				loader.hide()

				if (!statusMandar) {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
					return 
				}

				let error = await utils.addMessage(mensaje, cliente)

				if (error) {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				} else {
					useToast().success("Cliente actualizada", { position: "top-right" });	
				}


			}

			return {
				getPedidosForPeriod,
				previousPedidos,	
				previousPedidosFiltrados,
				currentPedidos,			
				periods,
				period,
				edit,
				cancelEdit,
				updatePedido,
				filter,
				copyToClipboard,
				mandarMensaje,
				faltantes,
				cancelados,
				pausados,
				futureFallidos,
				futureFaltantes
			}
		}
	}
</script>