<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Cliente</p>	
			<div class="dropdown dropdown-end">
				<div tabindex="0" role="button" class="btn btn-sm btn-primary">Mandar mensaje</div>
				<ul tabindex="0" class="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
					<li class="cursor-pointer" v-for="template in templates" v-bind:key="template.template" @click="mandarMensaje(template)"><a>{{template.nombre}}</a></li>										
				</ul>
			</div>
		</div>

		<div class="pt-4 space-y-4" v-if="cliente">
			<div class="flex flex-row w-full space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Nombre</p>
					<input class="input input-bordered input-sm w-full" placeholder="Nombre" type="text" v-model="cliente.nombre"/>
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Apellido</p>
					<input class="input input-bordered input-sm w-full" placeholder="Apellido" type="text" v-model="cliente.apellido"/>
				</div>
			</div>
			<div class="w-full">
				<p class="text-sm pb-2">Correo</p>
				<input class="input input-bordered input-sm w-full" placeholder="Correo" type="text" v-model="cliente.correo"/>
			</div>
			<div class="flex flex-row w-full space-x-4">			
				<div class="w-full">
					<p class="text-sm pb-2">Pais</p>
					<input class="input input-bordered input-sm w-full" placeholder="Lada" type="text" v-model="cliente.country_code"/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Número tel.</p>
					<input class="input input-bordered input-sm w-full" placeholder="Número" type="text" v-model="cliente.telefono"/>
				</div>
			</div>		

			<div class="flex flex-row w-full space-x-4">			
				<div class="w-full">
					<p class="text-sm pb-2">Calle</p>
					<input class="input input-bordered input-sm w-full" placeholder="Calle" type="text" v-model="cliente.direccion.calle"/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Exterior</p>
					<input class="input input-bordered input-sm w-full" placeholder="Exterior" type="text" v-model="cliente.direccion.exterior"/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Interior</p>
					<input class="input input-bordered input-sm w-full" placeholder="Interior" type="text" v-model="cliente.direccion.interior"/>
				</div>
			</div>		

			<div class="flex flex-row w-full space-x-4">			
				<div class="w-full">
					<p class="text-sm pb-2">Código postal</p>
					<input class="input input-bordered input-sm w-full" placeholder="Código postal" type="text" v-model="cliente.direccion.codigo_postal"/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Ciudad</p>
					<input class="input input-bordered input-sm w-full" placeholder="Ciudad" type="text" v-model="cliente.direccion.ciudad"/>
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Estado</p>
					<input class="input input-bordered input-sm w-full" placeholder="Estado" type="text" v-model="cliente.direccion.estado"/>
				</div>
			</div>				

			<div class="w-full">
				<p class="text-sm pb-2">Notas</p>
				<textarea class="input input-bordered input-sm w-full textarea h-20 px-3 py-0" placeholder="Notas" type="text" v-model="cliente.notas"/>
			</div>

			<button @click="updateCliente()" class="mt-4 btn btn-primary btn-sm no-animation w-full">Actualizar</button>
		</div>

		<div class="pt-4 space-y-4" v-if="cliente">
			<p class="text-xl">Club</p>	
			<div class="space-y-4">
				<div class="w-full">
					<p class="text-sm pb-2">Estatus</p>
					<select v-model="club.estatus" class="select select-sm select-bordered w-full">							
						<option selected disabled :value="null">Escoger un estatus</option>	
						<option value="activo">Activo</option>
						<option value="pausado">Pausado</option>				
						<option value="cancelado">Cancelado</option>				
					</select> 
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Cargo</p>
					<select v-model="club.cargo" class="select select-sm select-bordered w-full">							
						<option selected disabled :value="null">Escoger un tipo de cargo</option>	
						<option value="mensual">Mensual</option>
						<option value="anual">Anual</option>				
					</select> 
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Entrega</p>
					<select v-model="club.zona" class="select select-sm select-bordered w-full">							
						<option selected disabled :value="null">Escoger un tipo de entrega</option>	
						<option value="local">Local</option>
						<option value="foraneo">Foráneo</option>
						<option value="pickup">Pickup</option>				
					</select> 
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Club</p>
					<select v-model="club.club" class="select select-sm select-bordered w-full">							
						<option selected disabled :value="null">Escoger un club</option>	
						<option value="uno_blanco">Uno (Blanco)</option>
						<option value="uno_tinto">Uno (Tinto)</option>
						<option value="explorer">Explorer</option>		
						<option value="fearless">Fearless</option>		
						<option value="bold">Bold</option>								
						<option value="bright">Bright</option>								
					</select> 
				</div>
				<div class="w-full">
					<p class="text-sm pb-2">Notas</p>
					<textarea class="input input-bordered input-sm w-full textarea h-20 px-3 py-0" placeholder="Notas" type="text" v-model="club.notas"/>
				</div>

				<div v-if="club.estatus == 'cancelado'" class="w-full">
					<p class="text-sm pb-2">Fecha cancelación</p>
					<input class="input input-bordered input-sm w-full " type="date" v-model="club.fecha_cancelacion"/>
				</div>

				<div v-if="club.estatus == 'cancelado'" class="w-full">
					<p class="text-sm pb-2">Razón de cancelación</p>
					<textarea class="input input-bordered input-sm w-full textarea h-20 px-3 py-0" placeholder="Razón" type="text" v-model="club.razon_cancelacion"/>
				</div>


				

			</div>
			<button @click="updateClub()" class="mt-4 btn btn-primary btn-sm no-animation w-full">Actualizar</button>
		</div>

		<p class="text-xl mt-4">Pedidos</p>	

		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>						
						<th class="w-1/2">Fecha</th>								
						<th class="w-1/2">Producto</th>

					</tr>
				</thead>				
				<tbody>
					<tr v-for="pedido in pedidos" v-bind:key="pedido.id" class="">						
						<td>{{pedido.created_at.split("T")[0]}} {{pedido.pedido}}</td>
						<td><router-link :to=" `/pedido/${pedido.pedido}` ">{{pedido.producto ? pedido.producto.nombre : pedido.reference }}</router-link></td>
					</tr>
				</tbody>
			</table>
		</div>		

		<p class="text-xl mt-4">Catas</p>	

		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>						
						<th class="w-1/2">Fecha</th>								
						<th class="w-1/2">Ubicacion</th>

					</tr>
				</thead>				
				<tbody>
					<tr v-for="cata in catas" v-bind:key="cata.id" class="">						
						<td>{{cata.fecha}}</td>
						<td>{{cata.ubicacion.nombre }}</td>
					</tr>
				</tbody>
			</table>
		</div>		

		<p v-if="cliente && mensajes" class="text-xl mt-4">Mensajes</p>	

		<div v-if="cliente && mensajes" class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>						
						<th class="w-1/2">Fecha</th>								
						<th class="w-1/2">Mensaje</th>

					</tr>
				</thead>				
				<tbody>
					<tr v-for="mensaje in mensajes" v-bind:key="mensaje.id" class="">						
						<td>{{mensaje.created_at.split("T")[0]}}</td>
						<td>{{mensaje.nombre }}</td>
					</tr>
				</tbody>
			</table>
		</div>		


		<div v-if="cliente && cliente.shopify" class="flex justify-between items-center align-center mt-4">
			<p  class="text-xl ">Raw data</p>	
			<div @click="showRaw = !showRaw"> 
				<svg v-if="!showRaw" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
				</svg>

				<svg v-if="showRaw" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
				</svg>
			</div>
		</div>

		<pre v-if="showRaw">{{cliente && cliente.shopify ? cliente.shopify : ""}}</pre>

	</div>	
</template>
<script>

	import { supabase } from "../supabase"
	import { onMounted, ref, watch, computed } from "vue"
	import axios from "axios";
	import { useRoute } from 'vue-router'
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminCatas',
		setup() { 		
			const route = useRoute()

			const club = ref({})

			const mensajes = ref([])
			const pedidos = ref([])
			const cliente = ref(null)
			const hasClub = ref(false)

			const showRaw = ref(false)

			const catas = ref([])

			const loading = useLoading({
				loader: "spinner"
			});

			const templates = computed( () => { 				
				// if (!club.value.id) {
				// 	return []
				// }

				let beneficios_template;
				let envio_template;

				if (club.value.zona == "local" || club.value.zona == "pickup") {
					beneficios_template = "beneficio_local_v2"
					envio_template = "primer_envio_local_v3" // Utility 
				} else {
					beneficios_template = "beneficios_foraneo"
					envio_template = "primer_envio_foraneo_v2" // Utility 
				} 

				return [{
					template: "tocar_base_nombre", //Utility club
					nombre: "Tocar base"					
				},{
					template: "club_intro_v4", //Utility club
					nombre: "Bienvenida club"					
				},{
					template: "retro_llamada_v2", //Utility club productos
					nombre: "Retro llamada"
				},{
					template: "confirmar_sin_llamada_v6", //Utility club productos
					nombre: "Confirmar (sin llamada)"
				},{
					template: beneficios_template,
					nombre: "Beneficios"
				},{
					template: envio_template, //Utility
					nombre: "Primer envío"
				},{
					template: "checkin_primer_mes",
					nombre: "Checkin primer mes"
				},{
					template: "checkin_tercer_mes",
					nombre: "Checkin tercer mes"
				},{
					template: 'agendar_primera_cata',
					nombre: 'Agendar primera cata'
				},{
					template: 'follow_up_primera_cata',
					nombre: 'Follow up primera cata'
				},{
					template: 'cargo_fallido', //Utility
					nombre: 'Cargo fallido'
				}]
			})

			async function getCliente() {
				const { data, error } = await supabase
				.from('clientes')
				.select('*, pedidos(*)')
				.eq('id', route.params.id)		
				.single()				

				if (data) {
					return data
				} else if (error) {
					console.log(error)
				} else {
					alert("No data returned")
				}
			}	

			async function getPedidos() {
				const { data, error } = await supabase
				.from('pedidos')
				.select('*, pedido_detalles(id, reference, created_at, producto(*), pedido)')
				.eq('cliente', route.params.id)		
				.order('created_at', { ascending: false})			

				

				if (data) {
					return data
				} else if (error) {
					console.log(error)
				} else {
					alert("No data returned")
				}
			}

			async function updateCliente() {
				if (!cliente.value.country_code || !cliente.value.telefono || !cliente.value.correo || !cliente.value.nombre) {
					return alert ("Asegúrate que toda la info está")
				}

				let updatedCliente = {
					id: cliente.value.id,
					nombre: cliente.value.nombre,
					apellido: cliente.value.apellido,
					country_code: cliente.value.country_code,
					telefono: cliente.value.telefono.replaceAll(" ",""),
					correo:	cliente.value.correo,
					notas:	cliente.value.notas,
					direccion: cliente.value.direccion
				}			

				console.log(updatedCliente)

				const { error } = await supabase.from('clientes').upsert(updatedCliente);	

				if (error) {		
					console.log(error)
					return alert (error.details)
				}

				await updateShopify()

				useToast().success("Cliente actualizada", { position: "top-right" });	
			}

			async function addMessage(mensaje) {	
				console.log("Message to add", mensaje)			

				let m = {
					template: mensaje.template,
					nombre: mensaje.nombre,
					cliente: cliente.value.id
				}

				console.log(m)

				const { data, error } = await supabase.from('mensajes').insert(m).select().single()

				console.log(data ,error)
				if (error) {		
					console.log(error)
					return alert (error)
				} 

				mensajes.value.push(data)

				useToast().success("Cliente actualizada", { position: "top-right" });	
			}

			async function getClub() {
				const { data, error } = await supabase
				.from("suscripciones")
				.select("*")
				.eq('cliente', route.params.id)

				console.log(data, error)
				if (error) {		
					console.log(error)
					return alert (error.details)
				} else if (data && data.length > 0) {
					return data[0]
				}

				return 
			}

			async function updateClub() {
				club.value.cliente = route.params.id

				if (!club.value.estatus || !club.value.club || !club.value.zona || !club.value.cargo) {
					return alert ("Favor de llegar toda la info")
				}

				let loader = loading.show()

				const { data, error } = await supabase
				.from('suscripciones')
				.upsert([club.value])
				.select()

				loader.hide()

				if (error) {		
					console.log(error)
					useToast().error("No jaló", { position: "top-right" });	
				}

				useToast().success("Éxito", { position: "top-right" });	

				return data
			}

			async function updateShopify() {
				let loader = loading.show()

				axios.post("/api/customer", cliente.value).then( () => {
					loader.hide()
					useToast().success("Éxito", { position: "top-right" });	
				}).catch( e => {
					console.log(e)
					loader.hide()
					useToast().error("No pudo actualizar Shopify", { position: "top-right" });	
				}) 										
			}	


			async function getCatas() {
				const { data, error } = await supabase
				.from("catas")
				.select("*, ubicacion(nombre), reservas!inner(cliente)")
				.eq("reservas.cliente", route.params.id)
				.order('fecha', { ascending: false }) 

				console.log(data, error)
				if (error) {
					console.error(error);
				} else {
					return data
				}
			}

			async function getMensajes() {
				const { data, error } = await supabase
				.from("mensajes")
				.select("*")
				.eq("cliente", route.params.id)

				console.log(data, error)
				if (error) {
					console.error(error);
				} else {
					return data
				}
			}

			watch(hasClub, async (n) => {
				if (n == true && !cliente.value.club) {
					cliente.value.club = {}
				}
			})

			function getParams() {
				let params = [{
					"name": "nombre",
					"value": cliente.value.nombre
				},{
					"name": "apellido",
					"value": cliente.value.apellido
				},{
					"name": "correo",
					"value": cliente.value.correo
				}]

				switch (club.value.club) {
				case "explorer":
					params.push({
						name: "club",
						value: "Club Explorer"
					},{
						name: "productos",
						value: "te llegará un vino blanco y un vino tinto"
					})
					break
				case "fearless":
					params.push({
						name: "club",
						value: "Club Fearless"
					},{
						name: "productos",
						value: "te llegarán cuatro vinos"
					})
					break
				case "bold":
					params.push({
						name: "club",
						value: "Club Bold"
					},{
						name: "productos",
						value: "te llegarán dos vinos tintos"
					})
					break
				case "bright":
					params.push({
						name: "club",
						value: "Club Bright"
					},{
						name: "productos",
						value: "te llegarán dos vinos blancos"
					})
					break
				case "uno_blanco":
					params.push({
						name: "club",
						value: "Club Uno"
					},{
						name: "productos",
						value: "te llegará un vino blanco"
					})
					break
				case "uno_tinto":
					params.push({
						name: "club",
						value: "Club Uno"
					},{
						name: "productos",
						value: "te llegará un vino tinto"
					})
					break
				default:
					break	
				}					
				
				return params
			}

			async function mandarMensaje(mensaje) {	
				if(!confirm("¿Quieres mandar el mensaje?")){
					return 
				}			

				let loader = loading.show()					

				let { status } = await axios.post("/api/blast", {
					"template_name": mensaje.template,
					"broadcast_name": `mensaje_${mensaje.template}_${cliente.value.id}`,
					"receivers": [{
						"whatsappNumber": `${cliente.value.country_code.replace(/\D/g, "")}1${cliente.value.telefono}`,
						"customParams": getParams()		
					}]
				})

				loader.hide()

				if (status == 200) {
					useToast().success("Mensaje mandado", { position: "top-right" });	
				} else {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				}

				addMessage(mensaje)
			}			

			onMounted( async () => {  				
				getCliente().then( data => {					
					cliente.value = data
					if (!cliente.value.direccion) {
						cliente.value.direccion = {}
					}

					if (data.club) {
						hasClub.value = true;
					}
				})

				getPedidos().then( data => {
					pedidos.value = data.map( p => p.pedido_detalles).flat()
				})

				getClub().then( data => {
					if (data) {
						club.value = data
					}
				})

				getCatas().then( data => {
					catas.value = data
				})

				getMensajes().then( data => {
					console.log("mensajes", data)
					mensajes.value = data
				})

			})

			return {
				club,
				cliente,
				pedidos,
				mensajes,
				hasClub,
				showRaw,
				updateCliente,
				updateClub,
				catas,
				templates,
				mandarMensaje
			}
		}
	}
</script>