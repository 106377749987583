<template>

	<div class="flex items-center align-center justify-center h-full w-full">

		<div class="card card-compact bg-base-100 w-96 border">			
			<div class="card-body">
				<h2 class="card-title">Login</h2>
				
				<input type="text" v-model="correo" placeholder="Correo" autocomplete="username" class="input input-bordered w-full" />
				<input type="password" v-model="contra" placeholder="Contraseña" autocomplete="current-password" class="input input-bordered w-full" @keyup.enter="handleLogin"/>

				<div class="card-actions justify-end">
					<button @click="handleLogin()" class="btn btn-primary w-full" >Login</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import { supabase } from '../supabase'
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminCatas',
		setup() { 		
			const loading = useLoading({
				loader: "spinner"
			});

			const correo = ref(null)
			const contra = ref(null)

			const handleLogin = async () => {
				let loader = loading.show()
				try {
					
					const { error } = await supabase.auth.signInWithPassword({
						email: correo.value,
						password: contra.value,
					})					
					
					if (error) {
						alert('Hubo un error')
					}
				} catch (error) {
					loader.hide()
					if (error instanceof Error) {
						alert(error.message)
					}
				} finally {
					loader.hide()
				}
			}

			return {
				handleLogin,
				correo,
				contra
			}
		}
	}
</script>
