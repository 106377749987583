<template>
	<div class="p-4">
		
		<p class="text-2xl">Blasts</p>			

		
		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-4/12">Fecha</th>		
						<th class="w-4/12">Template</th>
						<th class="w-4/12">Nombre</th>										
						<th class="w-1/12"></th>										
					</tr>
				</thead>
				<tbody v-for="blast in blasts" v-bind:key="blast.id">
					<tr >						
						<td>{{blast.created_at.split("T")[0]}}</td>		
						<td>{{blast.req.template_name}}</td>	
						<td>{{blast.req.broadcast_name}}</td>	
						<td>
							<svg v-if="!showRaw[blast.id]"  @click="showRaw[blast.id] = true"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
							</svg>

							<svg v-if="showRaw[blast.id]"  @click="showRaw[blast.id] = false"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
							</svg>
						</td>								
					</tr>

					<tr v-if="showRaw[blast.id]">
						<td colspan="4" class="">
							<div class="flex flex-row space-x-4 w-full">
								<div class="w-full">
									Request
									<pre>{{blast.req}}</pre>
								</div>
								<div class="w-full">
									Response
									<pre>{{blast.res}}</pre>
								</div>
							</div>
							
						</td>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminProductos',
		setup() {	
			const blasts = ref([])
			const showRaw = ref({})
			
			async function getBlasts() {
				const { data } = await supabase
				.from('blasts')
				.select('*')							
				.order('created_at', { ascending: false })	

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}	
			
			onMounted( async () => {					
				blasts.value = await getBlasts()					
			})			

			return {
				showRaw,
				blasts
			}
		}
	}
</script>