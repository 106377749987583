<template>
	<div class="p-4">
		<p class="text-2xl">Revisar Salidas</p>
		
		<div className="flex w-full overflow-x-auto pt-4">
			<table className="table">
				<thead>
					<tr>
						<th>Fecha</th>
						<th>Shopify ID</th>
						<th>Reference</th>
						<th>Producto</th>			
						<th>Cantidad</th>	
						<th></th>							
						<th></th>						
						<th></th>						
						<th></th>
						<th></th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="(salida, i) in salidas" v-bind:key="salida.id">
						<th>							
							{{salida.created_at.split("T")[0]}}
						</th>
						<th>
							{{salida.shopify_id}}
						</th>
						<th>
							{{salida.reference}}
						</th>
						<th>
							{{salida.producto ? salida.producto.nombre : ""}}
						</th>
						<th>
							{{salida.cantidad}}
						</th>		
						<th>
							<p v-for="producto in salida.productos" v-bind:key="producto.id">{{producto.nombre}}</p>
						</th>
						<th>
							<select v-model="salida.producto" className="select select-sm select-block">							
								<option selected disabled value="">Escoger un producto</option>
								<option v-for="producto in productos" v-bind:key="producto.id" :value="producto"> {{ producto.nombre }} </option>
							</select>
						</th>		
						<th>
							<button @click="actualizarSalida(salida)" className="btn btn-primary btn-block">Actualizar</button>
						</th>	
						<th>
							<button @click="deleteSalida(salida)" className="btn btn-error btn-block">Eliminar</button>
						</th>
						<th>
							<button @click="duplicateSalida(salida, i)" className="btn btn-warning btn-block">Duplicar</button>
						</th>		
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>	
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminRevisarSalidas',
		setup() {	
			const productos = ref([])
			const salidas = ref([])

			async function getSalidas() {
				const { data } = await supabase
				.from('salidas')
				.select('*, reference, producto(id, nombre)')
				// .is('producto', null)
				.not("producto", "is", "null" )
				.not( "shopify_id", "is", "null" )
				.order('created_at', { ascending: false })	
				.order('shopify_id', { ascending: false })	
				.order('producto', { ascending: false })									

				if (data) {
					// return data
					// console.log(data)

					let original = Object.assign(data)

					return data.filter( s => {
						// console.log(s.shopify_id)

						let duplicate = original.filter( d => {	
							return d.shopify_id == s.shopify_id && d.producto.id == s.producto.id
						})	

						console.log(duplicate)

						if (duplicate.length > 1) {
							return true
						} else {
							return false
						}
					})
				} else {
					alert ("No data returned")
				}
			}

			async function getProductos() {
				const { data } = await supabase
				.from('productos')
				.select('id, nombre, proveedor (nombre)')								

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	
			
			async function deleteSalida(salida) {
				// if(confirm("¿Quieres borrar esta salida?")){
					if (salida.id) {
						await supabase
						.from('salidas')						
						.delete()
						.eq('id', salida.id)
					}
					
					const index = salidas.value.indexOf(salida);
					if (index > -1) {
						salidas.value.splice(index, 1);
					}
				// }
			}

			function duplicateSalida(salida, i) {
				let newSalida = Object.assign({}, salida)
				console.log(newSalida)
				delete newSalida.id
				delete newSalida.producto

				salidas.value.splice(i + 1, 0, newSalida);
			}

			async function actualizarSalida(salida) {
				if (!salida.producto) {
					return alert('Escoger un producto')
				}

				if(confirm("¿Quieres actualizar esta salida?")){
					let s = {
						shopify_id: salida.shopify_id,
						reference: null,
						producto: salida.producto.id,
						ubicacion: salida.ubicacion,
						cantidad: salida.cantidad
					}

					if (salida.id) {
						s.id = salida.id
					} else {
						s.created_at = salida.created_at
					}

					let { error } =  await supabase.from('salidas').upsert(s)

					if (error) {
						return alert(error.details)
					}
					
					const index = salidas.value.indexOf(salida);
					if (index > -1) {
						salidas.value.splice(index, 1);
					}
				}
			}

			onMounted( async () => {					
				salidas.value = await getSalidas()
				productos.value = await getProductos()
			})

			return {
				salidas,
				productos,
				deleteSalida,
				duplicateSalida,
				actualizarSalida			
			}
		}
	}
</script>