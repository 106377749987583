<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Cancelaciones</p>	
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-1/6">Nombre</th>
						<th class="w-1/6">Apellido</th>
						<th class="w-1/6">Correo</th>
						<th class="w-1/6">Fecha cancelación</th>							
						<th class="w-1/6">Cargo</th>
						<th class="w-1/6">Club</th>
					</tr>
				</thead>	


				<tbody v-for="club in clubs" v-bind:key="club.id" >
						<tr >
						<td class="cursor-pointer" @click="goToCliente(club.cliente.id)">{{club.cliente.nombre}}</td>
						<td class="cursor-pointer" @click="goToCliente(club.cliente.id)">{{club.cliente.apellido}}</td>
						<td class="cursor-pointer" @click="goToCliente(club.cliente.id)">{{club.cliente.correo}}</td>
						<td>{{club.fecha_cancelacion}}</td>							
						<td>{{club.cargo}}</td>	
						<td>{{club.club}}</td>	
						<td v-if="club.razon_cancelacion">
							<svg v-if="!showRazon[club.id]"  @click="showRazon[club.id] = true"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
							</svg>

							<svg v-if="showRazon[club.id]"  @click="showRazon[club.id] = false"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
							</svg>
						</td>												
					</tr>

					<!-- @click="goToCliente(club.cliente.id)" -->

					<tr v-if="showRazon[club.id]">
						<td colspan="7" class="">
							<div class="flex flex-row space-x-4 w-full">
								{{club.razon_cancelacion}}
							</div>
							
						</td>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useRouter } from 'vue-router'

	export default {
		name: 'ClientesList',
		setup() {	
			const clubs = ref([])
			const showRazon = ref([])

			const router = useRouter()			

			function goToCliente(cliente_id) {
				if (event.getModifierState('Meta')) {
					window.open(`/cliente/${cliente_id}`, '_blank').focus();
				} else {
					router.push(`/cliente/${cliente_id}`)
				}				
			}		

			async function getClubs() {	
				const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(id, nombre, apellido, telefono, country_code, correo)')
				.eq('estatus', 'cancelado')
				.order('fecha_cancelacion', { ascending: false })
				
				if (error) {
					console.error(error);
				} else {
					return data
				}			
			}

			onMounted( async () => {					
				getClubs().then( data => {
					clubs.value = data
				})								
			})

			return {			
				showRazon,				
				goToCliente,
				clubs				
			}
		}
	}
</script>