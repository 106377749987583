<template>
	<div class="p-4">
		<p class="text-2xl">Pedidos</p>

		<div class="flex space-x-4">

			<div class="pt-4 w-full">       
				<select v-model="ubicacion" @change="filterPedidos()" class="select select-sm select-bordered w-full">
					<option selected value="todos">Todos</option>
					<option v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id" :value="ubicacion"> {{ ubicacion.nombre }} </option>
				</select>
			</div>
		</div>		

		<div class="flex space-x-4 mt-2">
			<input class="input  input-bordered input-sm w-full" @change="refreshPedidos()" v-model="startDate" type="date">
			<input class="input  input-bordered input-sm w-full"  @change="refreshPedidos()" v-model="endDate" type="date">
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-1/4">Fecha</th>		
						<th class="w-1/4">Ubicación</th>
						<th class="w-1/4">Correo</th>									
						<th class="w-1/4">Total</th>						
						
					</tr>
				</thead>
				<tbody v-for="pedido in pedidos" v-bind:key="pedido.id">
					<tr>
						<td>
							<router-link :to="`/pedido/${pedido.id}`">{{pedido.created_at.split("T")[0]}}</router-link>
						</td>		
						<td>{{pedido.ubicacion.nombre}}</td>		
						<td>{{pedido.cliente ? pedido.cliente.correo : ""}}</td>					
						<td>${{pedido.total}}</td>								
						<td>
							<svg v-if="!showItems[pedido.id]"  @click="showItems[pedido.id] = true"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
							</svg>

							<svg v-if="showItems[pedido.id]"  @click="showItems[pedido.id] = false"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
								<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
							</svg>
						</td>		
					</tr>

					<tr v-if="showItems[pedido.id]">
						<td colspan="4" class="p-0">		
							<div class=''>					
								<table class="table table-xs">
									<thead class="sticky bg-base-100 top-0">
										<tr>
											<th class="w-1/3">Producto</th>		
											<th class="w-1/3">Cantidada</th>
											<th class="w-1/3">Total</th>																			

										</tr>
									</thead>
									<tbody v-for="pedido_detalle in pedido.pedido_detalles" v-bind:key="pedido_detalle.id">
										<tr>
											<td>{{pedido_detalle.producto.nombre}}</td>		
											<td>{{pedido_detalle.cantidad}}</td>		

											<td>${{pedido_detalle.total}}</td>																		
										</tr>									
									</tbody>
								</table>
							</div>
						</td>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminPedidos',
		setup() {	
			const pedidos = ref([])			

			const ubicaciones = ref([])
			const ubicacion = ref('todos')

			const startDate = ref("")
			const endDate = ref("")

			const showItems = ref({})

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 

				if (data) {					
					return data
				} else {
					alert("No data returned")
				}
			}

			async function filterPedidos() {
				pedidos.value = (await getPedidos()).filter( s => {
					if (ubicacion.value == "todos") {
						return true
					}

					return s.ubicacion.id == ubicacion.value.id
				})
			}

			async function getPedidos() {								
				const { data, error } = await supabase
				.from('pedidos')
				.select('*, cliente(nombre, apellido, correo), ubicacion(id, nombre), pedido_detalles(cantidad, producto(nombre), total, reference)')			
				.order('created_at', { ascending: false })	
				.filter('created_at', 'gte', startDate.value)
				.filter('created_at', 'lte', endDate.value)

				console.log(data, error)

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}			
			
			function refreshPedidos() {
				getPedidos().then( data => {
					pedidos.value = data
				})	
			}

			onMounted( async () => {					
				// const today = new Date();
				// endDate.value = today.toISOString().split('T')[0]
				// today.setMonth(today.getMonth() - 1);
				// startDate.value = today.toISOString().split('T')[0]

				startDate.value = getDateDaysAgo(7)
				endDate.value = getDateDaysAgo(0)	

				getPedidos().then( data => {
					pedidos.value = data
				})					
				getUbicaciones().then( data => {
					ubicaciones.value = data
				})			
			})

			function getDateDaysAgo(daysAgo) {
				return new Date(new Date().setDate(new Date().getDate() - daysAgo)).toISOString().split('T')[0];
			}	

			return {
				pedidos,
				refreshPedidos,
				filterPedidos,
				ubicaciones,
				ubicacion,
				startDate,
				endDate,
				getPedidos,
				showItems
			}
		}
	}
</script>