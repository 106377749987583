<template>
	<div class="p-4">
		<p class="text-2xl">Crear Proveedor</p>

		<div class="pt-4">	
			<input class="input input-bordered input-sm w-full" placeholder="Nombre" type="text" v-model="proveedor.nombre"/>
		</div>		

		<div class="pt-4">
			<button @click="crearProveedor()" class="btn btn-primary btn-sm w-full no-animation">Crear</button>
		</div>    
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useRoute } from 'vue-router'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'AdminProveedor',
		setup() { 
			const route = useRoute()

			const proveedor = ref({})

			async function getProveedor(id) {
				const { data } = await supabase
				.from('proveedores')
				.select('*')
				.eq("id", id)
				.single()
	
				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			} 

			async function crearProveedor() {       				
				if (!proveedor.value.nombre || proveedor.value.nombre == "") {
					return alert('Escoger un nombre')
				}

				let { error } = await supabase.from('proveedores').upsert(proveedor.value);				

				if (error) {
					return alert(error)
				}

				proveedor.value = { nombre: ""}

				useToast().success("Proveedor creado", { position: "top-right" });			
			}

			onMounted( async () => {  				
				if (route.params.proveedor_id) {
					proveedor.value = await getProveedor(route.params.proveedor_id)
				}
			})

			return {
				proveedor,
				crearProveedor
			}
		}
	}
</script>