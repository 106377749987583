<template>
	<div class="p-4">
		<div class="flex justify-between">			
			<div v-if="cata && cata.ubicacion" class="">
				<p class="text-2xl">Cata - {{cata.ubicacion.nombre}} {{formatDateEuroStyle(cata.fecha)}}</p>
			</div>
			<div v-if="!viewOnly" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="toggle()">
				<svg v-if="!showNewReserva" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
				</svg>
				<svg v-if="showNewReserva" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
				</svg>
			</div>
		</div>

		<div v-if="showNewReserva">
			<p class="text-xl pt-4">Reserva</p>
			<div class="pt-4 space-y-4">
				<div class="w-full">
					<div class="flex flex-row space-x-4">
						<input class="input input-bordered input-sm w-full" placeholder="Buscar" type="text" v-model="searchText"/>
						<button @click="newCliente()" class="btn btn-primary btn-sm no-animation ">Nuevo cliente</button>
					</div>    

					<div v-if="searchClientes.length > 0" class="flex w-full mt-4">
						<table class="table table-xs">
							<thead class="sticky bg-base-100 top-0">
								<tr>
									<th class="w-1/5">Nombre</th>								
									<th class="w-1/5">Correo</th>
									<th class="w-1/5">Teléfono</th>
									<th class="w-1/5">Club</th>
									<th class="w-1/5">Estatus</th>
								</tr>
							</thead>				
							<tbody>
								<tr v-for="c in searchClientes" v-bind:key="c.id" class="cursor-pointer" @click="cliente = c, searchText = ''">
									<td>{{c.nombre}} {{c.apellido}}</td>
									<td>{{c.correo}}</td>	
									<td>{{c.country_code}} {{c.telefono}}</td>	
									<td>{{c.suscripciones.length > 0 ? c.suscripciones[0].club : ""}}</td>						
									<td>{{c.suscripciones.length > 0 ? c.suscripciones[0].estatus : ""}}</td>						
								</tr>
							</tbody>
						</table>
					</div>	
				</div>	

				<div class="flex flex-row w-full space-x-4">
					<div v-if="cliente" class="w-full">
						<p class="text-sm pb-2">Cliente</p>
						<div class="flex flex-row space-x-4"> 
							<input disabled class="input input-bordered input-sm w-full" placeholder="Nombre" type="text" v-model="cliente.nombre"/>
							<input disabled class="input input-bordered input-sm w-full" placeholder="Correo" type="text" v-model="cliente.correo"/>
							<input disabled class="input input-bordered input-sm w-full" placeholder="Telefono" type="text" v-model="cliente.telefono"/>
							<div @click="cliente = null">
								<div class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer">
									<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
						</div>
					</div>

					
				</div>

				<div class="flex flex-row w-full space-x-4">

					<div class="w-1/5">
						<p class="text-sm pb-2">Primera Vez</p>
						<input class="checkbox no-animation h-8 w-8" type="checkbox" v-model="primera_vez"/>
					</div>

					<div class="w-2/5">
						<p class="text-sm pb-2">Acompañantes</p>
						<input class="input input-bordered input-sm w-full" placeholder="0" type="number" inputmode="numeric" v-model="adicionales"/>
					</div>

					<div class="w-2/5">
						<p class="text-sm pb-2">Estatus</p>
						<select v-model="estatus" className="select select-sm select-block select-bordered w-full">		
							<option disabled selected value="">Escoge un estatus</option>
							<option v-for="estatus in estatuses" v-bind:key="estatus" :value="estatus"> {{ estatus }} </option>
						</select>	
					</div>
				</div>

				<div class="flex flex-row w-full space-x-4 ">					
					<div class="w-full">
						<p class="text-sm pb-2">Tipo</p>
						<select v-model="type" className="select select-sm select-block select-bordered w-full">		
							<option disabled selected value="">Escoge un tipo</option>
							<option value="Club"> Club </option>
							<option value="Publico"> Publico </option>
						</select>
					</div>

					<div class="w-full">
						<p class="text-sm pb-2">Notas</p>
						<input class="input input-bordered input-sm w-full" placeholder="Notas" type="text" v-model="notas"/>
					</div>
				</div>
				<button @click="createReserva(cliente, adicionales, estatus, type, primera_vez, notas)" class="btn btn-primary btn-sm no-animation w-full">Crear</button>
			</div>

		</div>

		<div v-if="cata" class="mt-4 flex flex-row space-x-4">
			<select v-model="cata.cancelado" className="select select-sm select-block select-bordered w-1/2">		
				<option  :value="false"> Activo </option>
				<option  :value="true"> Cancelado </option>
			</select>

			<button @click="updateCata" class="btn btn-primary btn-sm no-animation w-1/2">Guardar</button>
		</div>

		<p class="text-xl pt-4">Resumen</p>
		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-1/6">Pendientes</th>
						<th class="w-1/6">Confirmados</th>
						<th class="w-1/6">Waitlist</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{reservas.filter( r => r.estatus == "Pendiente").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
						<td>{{reservas.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
						<td>{{reservas.filter( r => r.estatus == "Waitlist").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<p class="text-xl pt-4">Reservas</p>

		<div class="flex w-full pt-4  overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-2/12">Nombre</th>
						<th class="w-2/12">Correo</th>
						<th class="w-1/12">Número tel.</th>
						<th class="w-1/12">Acompañantes</th>
						<th class="w-1/12">Primera vez</th>
						<th class="w-2/12">Estatus</th>	
						<th class="w-1/12">Tipo</th>	
						<th class="w-2/12">Notas</th>			
						<th v-if="!viewOnly" class="w-1/12"></th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="reserva in reservas" v-bind:key="reserva.id">
						<td>							 
							<p class="cursor-pointer" v-if="reserva.cliente">
								<router-link :to="`/cliente/${reserva.cliente.id}`">
									{{reserva.cliente.nombre}} {{reserva.cliente.apellido}}
								</router-link>
							</p>
							<p v-if="!reserva.cliente">{{reserva.cliente_texto}}</p>							
						</td>	
						<td>
							<p v-if="reserva.cliente">{{reserva.cliente.correo}}</p>
							<p v-if="!reserva.cliente">{{reserva.correo}}</p>
						</td>	
						<td>
							<p v-if="reserva.cliente">{{reserva.cliente.country_code}} {{reserva.cliente.telefono}}</p>
							<p v-if="!reserva.cliente">{{reserva.numero}}</p>
						</td>		
						<td>
							<p v-if="!edit[reserva.id]">{{reserva.adicionales}}</p>
							<input v-if="edit[reserva.id]" class="input input-bordered input-sm w-full" placeholder="" type="number" inputmode="numeric" v-model="reserva.adicionales"/>
						</td>		
						<td>
							<p v-if="!edit[reserva.id]">{{reserva.primera_vez ? "Si" : "No"}}</p>
							<input v-if="edit[reserva.id]" class="checkbox no-animation" type="checkbox" v-model="reserva.primera_vez"/>
						</td>				
						<td>
							<p v-if="!edit[reserva.id]">{{reserva.estatus}}</p>
							<select v-if="edit[reserva.id]" v-model="reserva.estatus" className="select select-sm select-block select-bordered">			
								<option v-for="estatus in estatuses" v-bind:key="estatus" :value="estatus"> {{ estatus }} </option>
							</select>	
						</td>
						<td>
							<p v-if="!edit[reserva.id]">{{reserva.type}}</p>

							<select v-if="edit[reserva.id]" v-model="reserva.type" className="select select-sm select-block select-bordered w-full">		
								<option value="Club"> Club </option>
								<option value="Publico"> Publico </option>
							</select>

						</td>	
						<td>
							<p v-if="!edit[reserva.id]">{{reserva.notas}}</p>
							<input v-if="edit[reserva.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="reserva.notas"/>
						</td>
						<td v-if="!viewOnly">
							<button v-if="!edit[reserva.id]" @click="edit[reserva.id] = true" class="btn btn-primary btn-sm no-animation w-full">Editar</button>

							<div v-if="edit[reserva.id]" class="flex flex-row w-full space-x-2">
								<button @click="updateReserva(reserva)" class="btn btn-primary btn-sm no-animation">Guardar</button>

								<div class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="cancelEdit(reserva);">
									<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<button @click="blast()" class="mt-4 btn btn-primary btn-sm no-animation w-full">Mandar confirmación</button>

	</div>	
</template>
<script>

	import { supabase } from "../supabase"
	import { onMounted, ref, watch} from "vue"
	import { useRoute } from 'vue-router'
	import { useToast } from 'vue-toast-notification';
	import axios from "axios";
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminCatas',
		setup() { 
			let route = useRoute()

			let reservas = ref([])
			let reservasCopy = ref([])	

			let cata = ref(null)

			let cliente = ref(null)
			let adicionales = ref(0)
			let estatus = ref("Pendiente")
			let type = ref("Club")
			let notas = ref("")
			let primera_vez = ref(null)

			let showNewReserva = ref(false)

			let searchText = ref("")
			let searchClientes = ref([])
			let timeout;
			let ac;

			let edit = ref({})

			let estatuses = ["Pendiente", "Confirmado", "Cancelado", "Waitlist"] 

			let viewOnly = ref(false)

			const loading = useLoading({
				loader: "spinner"
			});

			async function searchCliente(text) {
				const terms = text.split(' ');
				let query = supabase.from('clientes').select('*, suscripciones(*)');

				terms.forEach((term, index) => {
					const ilikeCondition = `nombre.ilike.%${term}%,apellido.ilike.%${term}%,correo.ilike.%${term}%`;

					if (index === 0) {  
						query = query.or(ilikeCondition);
					} else {      
						query = query.or(ilikeCondition);
					}
				});

				const { data, error } = await query;

				console.log(data, error)

				if (data) {
					return data
				} else {
					alert("No data returned")
				}				
			}

			watch(searchText, async (newSearchText, oldSearchText) => {
				if (newSearchText == "") {
					searchClientes.value = []
					return
				}

				console.log(newSearchText, oldSearchText)

				if (ac) {
					ac.abort()
				}

				clearTimeout(timeout);

				timeout = setTimeout(() => {
					executeSearch(newSearchText);
				}, 1000);

			})


			async function executeSearch(text) {
				searchCliente(text)
				.then( data => {
					searchClientes.value = data
				})
			}

			async function getCata() {
				const { data, error } = await supabase
				.from('catas')
				.select('*, ubicacion(id, nombre, capacidad)')
				.eq('id', route.params.cata_id)		
				.single()		

				if (data) {
					return data
				} else if (error) {
					console.log(error)
				} else {
					alert("No data returned")
				}
			}	

			async function getReservas() {
				const { data, error } = await supabase
				.from('reservas')
				.select('*, cliente(id, nombre, apellido, telefono, country_code, correo)')
				.eq('cata', route.params.cata_id)	
				.order('estatus', { ascending: true }) 			

				if (data) {
					return data
				} else if (error) {
					console.log(error)
				} else {
					alert("No data returned")
				}
			}				

			async function updateReserva(reserva) {
				if(!confirm("¿Quieres guardar tus cambios?")){
					return cancelEdit(reserva)
				}

				if (!reserva.id) {
					return alert('No existe la reserva.')
				}

				let total = reservas.value.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)

				console.log(total, cata.value.ubicacion.capacidad)

				if(total > cata.value.ubicacion.capacidad && !confirm("Estás arriba del limite de capacidad. ¿Seguir con el cambio?")){
					reservas.value = structuredClone(reservasCopy.value)
					edit.value[reserva.id] = false
					return
				} 

				let reservaCopy = structuredClone(reserva)

				console.log(reservaCopy)

				reservaCopy.cliente = reservaCopy.cliente.id

				const { error } = await supabase.from('reservas').upsert(reservaCopy);	

				if (error) {
					console.log(error)
					return alert (error)
				}

				reservasCopy.value = structuredClone(reservas.value)

				useToast().success("Reserva actualizada", { position: "top-right" });	

				edit.value[reserva.id] = false
			}

			async function updateCata() {
				if(!confirm("¿Quieres guardar tus cambios?")){
					return 
				}
				
				const { error } = await supabase.from('catas').update({ cancelado: cata.value.cancelado }).eq("id", cata.value.id);	

				if (error) {
					console.log(error)
					return alert (error)
				}
				
				useToast().success("Cata actualizada", { position: "top-right" });	
			}

			function formatDateEuroStyle(fecha) {
				let components = fecha.split("-")

				return `${components[2]}/${components[1]}/${components[0]}`
			}

			async function createReserva(c, a, e, t, p, no) {
				let total = reservas.value.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)

				if((total + 1 + a) > cata.value.ubicacion.capacidad && !confirm("¿Quieres crear esta reserva?")){
					return
				}

				if (!c) {
					return alert("Llénalo bien porfa")
				}

				let loader = loading.show()

				let newReserva = {
					cliente: c.id,
					adicionales: a,
					estatus: e,
					type: t,
					notas: no,
					cata: cata.value.id,
					primera_vez: p
				}

				console.log(newReserva)

				const { data, error } = await supabase.from('reservas')
				.upsert(newReserva)
				.select('*, cliente(id, nombre, apellido, telefono, country_code, correo)')
				.single();				

				console.log(data, error)

				if (error) {
					return alert (error.details)
				}

				reservas.value.push(data)	

				useToast().success("Reserva creada", { position: "top-right" });

				loader.hide()

				toggle()

				cliente.value = null					
				adicionales.value = 0
				estatus.value = "Pendiente"
				type.value = "Club"
				notas.value = ""
			}

			function cancelEdit(reserva) {
				edit.value[reserva.id] = false
				reservas.value = structuredClone(reservasCopy.value)			
			}

			function toggle() {
				showNewReserva.value = !showNewReserva.value				
			}

			async function blast() {								
				if (!confirm('¿Estás seguro del blast?')) {
					return
				} 

				let loader = loading.show()

				let clubsToBlast = reservas.value.filter( r => r.estatus == "Confirmado" && r.cliente ).map( r => {
					return {
						"whatsappNumber": `${r.cliente.country_code.replace(/\D/g, "")}1${r.cliente.telefono}`,		
						"customParams": [{
							"name": "nombre",
							"value": r.cliente.nombre
						},{
							"name": "apellido",
							"value": r.cliente.apellido
						},{
							"name": "correo",
							"value": r.cliente.correo
						}]				
					}
				})

				console.log(clubsToBlast)

				let template

				if (cata.value.ubicacion.id == 13) {
					template = "confirmar_cata_sm_v2"
				} else if (cata.value.ubicacion.id == 5) {
					template = "confirmar_cata_roma_v4"
				} else if (cata.value.ubicacion.id == 4) {
					template = "confirmar_cata_polanco_v2"
				} else if (cata.value.ubicacion.id == 3) {
					template = "confirmar_cata_condesa_v2"
				}

				console.log(template, clubsToBlast)

				let { status }  = await axios.post("/api/blast", {
					"template_name": template,
					"broadcast_name": `confirm_cata_${cata.value.ubicacion.nombre}_${cata.value.fecha.replaceAll("-","")}`,
					"receivers": clubsToBlast										
				})	

				loader.hide()

				if (status == 200) {
					useToast().success("Mensajes mandado", { position: "top-right" });	
				} else {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				}

				console.log(status)
			}

			onMounted( async () => {  				
				getReservas().then( data => {
					reservas.value = data
					reservasCopy.value = JSON.parse(JSON.stringify(data))
				})

				getCata().then( data => {
					console.log(data)
					cata.value = data
				})

				if (route.query.view) {
					viewOnly.value = true
				}
			})

			function newCliente() {
				window.open('/create_cliente', '_blank').focus();

			}

			return {
				cata,
				reservas,
				estatuses,
				updateReserva,
				createReserva,
				formatDateEuroStyle,
				cliente,						
				adicionales,
				estatus,
				type,
				notas,
				toggle,
				primera_vez,
				edit,
				showNewReserva,
				viewOnly,
				reservasCopy,
				cancelEdit,
				searchText,
				searchClientes,
				blast,
				newCliente,
				updateCata
			}
		}
	}
</script>