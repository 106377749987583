<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Blast</p>	
		</div>
		
		<div class="pt-4 space-y-4">
			<div class="w-full space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Nombre</p>
					<input class="input input-bordered input-sm w-full" placeholder="Nombre" type="text" v-model="broadcast"/>
				</div>
			</div>

			<div class="w-full">
				<p class="text-sm pb-2">Template</p>
				<select v-model="template" class="select select-sm select-bordered w-full">							
					<option selected disabled :value="null">Escoger un template</option>	
					<option v-for="template in templates" v-bind:key="template.elementName" :value="template.elementName"> {{ template.elementName }} </option>						
				</select> 
			</div>

			<div class="w-full">
				<p class="text-sm pb-2">Cargo</p>
				<select v-model="cargo" class="select select-sm select-bordered w-full">							
					<option selected disabled :value="null">Escoger un tipo de cargo</option>	
					<option value="mensual">Mensual</option>
					<option value="anual">Anual</option>				
				</select> 
			</div>

			<div class="w-full">
				<p class="text-sm pb-2">Zona</p>
				<select v-model="zona" class="select select-sm select-bordered w-full">							
					<option selected disabled :value="null">Escoger una zona</option>	
					<option value="local">Local</option>
					<option value="foraneo">Foráneo</option>				
				</select> 
			</div>
		</div>
		
		<button @click="blast()" class="mt-4 btn btn-primary btn-sm no-animation w-full">Blast</button>

		<p class="text-xl mt-4">Clientes ({{filteredClubs.length}})</p>	

		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th class="w-1/5">Nombre</th>								
						<th class="w-1/5">Telefono</th>
						<th class="w-1/5">Club</th>
						<th class="w-1/5">Cargo</th>						
						<th class="w-1/5">Zone</th>						
					</tr>
				</thead>				
				<tbody>
					<tr v-for="club in filteredClubs" v-bind:key="club.id" class="cursor-pointer">
						<td @click="goToCliente(club.cliente.id)">{{club.cliente.nombre}} {{club.cliente.apellido}}</td>
						<td>{{club.cliente.country_code}} {{club.cliente.telefono}}</td>
						<td>{{club.club}}</td>
						<td>{{club.cargo}}</td>
						<td>{{club.zona}}</td>						
					</tr>
				</tbody>
			</table>
		</div>	
	</div>	
</template>
<script>

	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"
	import axios from "axios";
	import { useRouter } from 'vue-router'
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminCatas',
		setup() { 		
			const router = useRouter()

			const cargo = ref(null)
			const zona = ref(null)
			const club = ref(null)

			const broadcast = ref(null)
			const template = ref(null)
			const templates = ref([])			
			const clubs = ref([])

			const loading = useLoading({
				loader: "spinner"
			});

			async function getTemplates() {
				let { data }  = await axios.get("/api/templates")		

				return data			
			}		

			async function getClubs() {							
				const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(id, nombre, apellido, telefono, country_code)')
				.eq('estatus', "activo")				

				console.log(data, error)
				if (error) {
					console.error(error);
				} else {
					return data
				}
			}		

			async function blast() {
				if (!broadcast.value) {
					return alert('Favor de ingresar un nombre')
				}

				if (!template.value) {
					return alert('Favor de escoger un template')
				}

				if (!zona.value) {
					return alert('Favor de escoger una zona')
				}

				if (!cargo.value) {
					return alert('Favor de escoger un cargo')
				}

				if (!confirm('¿Estás seguro del blast?')) {
					return
				} 

				let loader = loading.show()

				let clubsToBlast = filteredClubs.value.map( c => {
					return {
						"whatsappNumber": `${c.cliente.country_code.replace(/\D/g, "")}1${c.cliente.telefono}`,		
						"customParams": [{
							"name": "nombre",
							"value": c.cliente.nombre
						},{
							"name": "apellido",
							"value": c.cliente.apellido
						},{
							"name": "correo",
							"value": c.cliente.correo
						}]				
					}
				})
				
				let { status }  = await axios.post("/api/blast", {
					"template_name": template.value,
					"broadcast_name": broadcast.value,
					"receivers": clubsToBlast
				})		

				loader.hide()

				console.log(status)		

				cargo.value = null
				zona.value = null
				broadcast.value = null
				template.value = null

				if (status == 200) {
					useToast().success("Mensajes mandado", { position: "top-right" });	
				} else {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				}

				await addMessages(template, broadcast)
			}

			async function addMessages(template, broadcast) {
				let mensajes = filteredClubs.value.map( c => {
					return {
						template: template.value,
						nombre: broadcast.value,
						cliente: c.cliente.id
					}
				})							

				const { error } = await supabase.from('mensajes').upsert(mensajes)

				if (error) {		
					console.log(error)
					return alert (error)
				} 				

				useToast().success("Clientes actualizada", { position: "top-right" });	
			}

			const filteredClubs = computed( () => {
				return clubs.value.filter( c => c.zona == zona.value && c.cargo == cargo.value)				
			})

			function goToCliente(cliente_id) {
				if (event.getModifierState('Meta')) {
					window.open(`/cliente/${cliente_id}`, '_blank').focus();
				} else {
					router.push(`/cliente/${cliente_id}`)
				}				
			}

			onMounted( async () => {  				
				getClubs().then( data => {	
					console.log(data)				
					clubs.value = data					
				})

				getTemplates().then( data => {				
					templates.value = data.messageTemplates.filter( t => t.status == "APPROVED" && t.elementName.includes('blast'))	
				})
			})

			return {		
				filteredClubs,
				goToCliente,
				broadcast,
				template,		
				templates,
				clubs,
				blast,
				zona,
				cargo,
				club
			}
		}
	}
</script>