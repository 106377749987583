<template>
	<div class="p-4">
		<p class="text-2xl">Entradas</p>
			
			<div class="flex space-x-4">

			<div class="pt-4 w-full">       
				<select v-model="ubicacion" @change="refreshEntradas()" class="select select-sm select-bordered w-full">
					<option selected value="todos">Todos</option>
					<option v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id" :value="ubicacion"> {{ ubicacion.nombre }} </option>
				</select>
			</div>

			<div class="pt-4 w-full">       
				<select v-model="proveedor" @change="refreshEntradas()" class="select select-sm select-bordered w-full">
					<option selected value="todos">Todos</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor"> {{ proveedor.nombre }} </option>
				</select>
			</div>

		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th>Fecha</th>		
						<th>Ubicación</th>						
						<th>Producto</th>					
						<th>Cantidad</th>
						<th>Reference</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="entrada in entradas" v-bind:key="entrada.id">
						<th>{{entrada.created_at.split("T")[0]}}</th>		
						<th>{{entrada.ubicacion.nombre}}</th>								
						<th>{{entrada.producto ? entrada.producto.nombre : ""}}</th>		
						<th>{{entrada.cantidad}}</th>		
						<th>{{entrada.reference}}</th>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminEntradas',
		setup() {	
			const entradas = ref([])
			const proveedores = ref([])
			const proveedor = ref('todos')
			const ubicaciones = ref([])
			const ubicacion = ref('todos')

			async function getProveedores() {
				const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			} 

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 

				if (data) {					
					return data
				} else {
					alert("No data returned")
				}
			}

			async function getEntradas() {
				const { data } = await supabase
				.from('entradas')
				.select('id, created_at, producto (nombre, proveedor), reference, cantidad, ubicacion (id, nombre)')							
				.order('created_at', { ascending: false })	

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}	

			async function refreshEntradas() {
				entradas.value = (await getEntradas()).filter( e => {
					if (ubicacion.value == "todos") {
						return true
					}

					return e.ubicacion.id == ubicacion.value.id
				}).filter( e => {
					if (proveedor.value == "todos") {
						return true
					}

					if (!e.producto) {
						return false 
					}

					if (proveedor.value) {
						return e.producto.proveedor && e.producto.proveedor == proveedor.value.id
					} 

					return true
				})
			}
			
			onMounted( async () => {					
				entradas.value = await getEntradas()	
				proveedores.value = await getProveedores()				
				ubicaciones.value = await getUbicaciones()			
			})

			return {
				entradas,
				proveedores,
				proveedor,
				ubicaciones,
				ubicacion,
				refreshEntradas
			}
		}
	}
</script>