import { createApp } from 'vue/dist/vue.esm-bundler';
import ToastPlugin from 'vue-toast-notification';
import { LoadingPlugin } from 'vue-loading-overlay';
import App from './App.vue'
import router from "./router";

import 'vue-loading-overlay/dist/css/index.css';
import './assets/tailwind.css'
import './assets/toast.css'

const app = createApp(App);

app.use(ToastPlugin);
app.use(LoadingPlugin);
app.use(router);
app.mount("#app");
