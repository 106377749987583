<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">{{route.params.producto_id ? "Editar" : "Crear" }} Producto</p>	

			<div class="">
				<button @click="importMeta()" class="btn btn-sm w-full btn-primary no-animation ">Import</button>
			</div>   
		</div>
		<div class="flex flex-row space-x-4">
			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">Nombre</p>
				<input class="input input-bordered input-sm w-full" placeholder="Nombre" type="text" v-model="producto.nombre"/>
			</div>
			<div class="pt-4 w-full">      
				<p class="pb-1">Proveedor</p> 
				<select v-model="producto.proveedor" class="select select-sm select-bordered w-full"> 
					<option selected disabled value="">Escoger un proveedor</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor"> {{ proveedor.nombre }} </option>
				</select>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-4 pt-4">
			<div>
				<img class="w-full h-full aspect-square rounded-lg overflow-hidden bg-gray" :src="producto.photo"/>
			</div>

			<div class="">					
				<input type="file" ref="file" id="product_foto" style="display: none" @change="uploadFile($event)" />					
				<button class="btn btn-outline btn-sm w-full no-animation" @click="$refs.file.click()" >Upload</button>
			</div>	

		</div>
		<div class="flex flex-row space-x-4">
			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">Costo</p>
				<input class="input input-bordered input-sm w-full" placeholder="Costo" type="number" v-model="producto.costo"/>
			</div>
			<div class="pt-4 w-full">
				<p class="pb-1 text-sm">Precio</p>
				<input class="input input-bordered input-sm w-full" placeholder="Precio" type="number" v-model="producto.precio"/>
			</div>
		</div>
		<div v-if="producto.id" class="flex flex-row space-x-4">
			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">Shoipfy ID</p>
				<input disabled class="input input-bordered input-sm w-full" placeholder="Shopify ID" type="text" v-model="producto.shopify_id"/>
			</div>
			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">Shopify Handle</p>
				<input disabled class="input input-bordered input-sm w-full" placeholder="Shopify Handle" type="text" v-model="producto.shopify_handle"/>
			</div>		
		</div>
		<div class="pt-4 w-full">  
			<p class="pb-1 text-sm">Activo</p>     
			<select v-model="producto.activo" class="select select-sm select-bordered w-full"> 
				<option value=true>Activo</option>
				<option value=false>Desactivado</option>
			</select>
		</div>

		<div class="pt-4 w-full">  
			<p class="pb-1">Tipo</p> 
			<select v-model="producto.meta.tipo" class="select select-sm select-bordered w-full"> 
				<option selected disabled value="">Escoger un tipo</option>
				<option value="Tinto"> Tinto </option>
				<option value="Blanco"> Blanco </option>
				<option value="Rosado"> Rosado </option>
				<option value="Espumoso"> Espumoso </option>
			</select>
		</div>

		<div class="flex flex-row space-x-4">
			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">País</p>
				<input class="input input-bordered input-sm w-full" placeholder="País" type="text" v-model="producto.meta.pais"/>
			</div>

			<div class="pt-4 w-full">	
				<p class="pb-1 text-sm">Región</p>
				<input class="input input-bordered input-sm w-full" placeholder="Región" type="text" v-model="producto.meta.region"/>
			</div>

		</div>
		<div class="pt-4">	
			<p class="pb-1 text-sm">¿Porque está chido?</p>
			<textarea class="input input-bordered input-sm w-full textarea h-20 px-3 py-0" placeholder="Explicálo bien" type="text" v-model="producto.meta.dato"/>
		</div>

		<div class="pt-4 flex flex-row space-x-4">
			<div class="w-full">
				<p class="pb-1 text-sm">Ácidez</p>
				<input class="input input-bordered input-sm w-full" placeholder="" type="number" min="0" max="5" v-model="producto.meta.acidez"  @change="validateQuantity('acidez', producto.meta.acidez)"/>
			</div>

			<div class="w-full">
				<p class="pb-1 text-sm">Tanito</p>
				<input class="input input-bordered input-sm w-full" placeholder="" type="number" min="0" max="5" v-model="producto.meta.tanino"  @change="validateQuantity('tanino', producto.meta.tanino)"/>
			</div>

			<div class="w-full">
				<p class="pb-1 text-sm">Frutalidad</p>
				<input class="input input-bordered input-sm w-full" placeholder="" type="number" min="0" max="5" v-model="producto.meta.frutalidad"  @change="validateQuantity('frutalidad', producto.meta.frutalidad)"/>
			</div>

			<div class="w-full">
				<p class="pb-1 text-sm">Cuerpo</p>
				<input class="input input-bordered input-sm w-full" placeholder="" type="number" min="0" max="5" v-model="producto.meta.cuerpo"  @change="validateQuantity('cuerpo', producto.meta.cuerpo)"/>
			</div>
		</div>


		<div class="pt-4">	
			<p class="pb-1 text-sm">Perfiles</p>
			{{perfil}}
			<select v-model="perfil" class="select select-sm select-bordered w-full" @change="addElement('perfiles', perfil)">> 
				<option selected disabled value="">Escoger un perfil</option>
				<option selected value="XNUEVOX">Agrega nuevo</option>
				<option v-for="perfil in perfiles" v-bind:key="perfil" :value="perfil"> {{ perfil }} </option>
			</select>

			<div class="mt-2 border border-1 border-slate-700 rounded-lg w-full flex flex-row h-10 items-center px-1 space-x-1">		
				<div v-for="perfil in producto.meta.perfiles" v-bind:key="perfil" class="border border-1 border-slate-700 rounded-lg flex flex-row justify-between space-x-2 px-2 h-8 items-center">
					<div class="text-sm">{{perfil}}</div>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 cursor-pointer" @click="removeElement('perfiles', perfil)">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
				</div>
			</div>
		</div>

		<div class="pt-4">	
			<p class="pb-1 text-sm">Personalidades</p>

			<select v-model="personalidad" class="select select-sm select-bordered w-full" @change="addElement('personalidades', personalidad)">> 
				<option selected disabled value="">Escoger una personalidad</option>
				<option selected value="XNUEVOX">Agrega nuevo</option>
				<option v-for="personalidad in personalidades" v-bind:key="personalidad" :value="personalidad"> {{ personalidad }} </option>
			</select>

			<div class="mt-2 border border-1 border-slate-700 rounded-lg w-full flex flex-row h-10 items-center px-1 space-x-1">		
				<div v-for="personalidad in producto.meta.personalidades" v-bind:key="personalidad" class="border border-1 border-slate-700 rounded-lg flex flex-row justify-between space-x-2 px-2 h-8 items-center">
					<div class="text-sm">{{personalidad}}</div>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 cursor-pointer" @click="removeElement('personalidades', personalidad)">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
				</div>
			</div>
		</div>

		<div class="pt-4">	
			<p class="pb-1 text-sm">Situaciones</p>

			<select v-model="situacion" class="select select-sm select-bordered w-full" @change="addElement('situaciones', situacion)">> 
				<option selected disabled value="">Escoger una situacion</option>
				<option selected value="XNUEVOX">Agrega nuevo</option>
				<option v-for="situacion in situaciones" v-bind:key="situacion" :value="situacion"> {{ situacion }} </option>
			</select>

			<div class="mt-2 border border-1 border-slate-700 rounded-lg w-full flex flex-row h-10 items-center px-1 space-x-1">		
				<div v-for="situacion in producto.meta.situaciones" v-bind:key="situacion" class="border border-1 border-slate-700 rounded-lg flex flex-row justify-between space-x-2 px-2 h-8 items-center">
					<div class="text-sm">{{situacion}}</div>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 cursor-pointer" @click="removeElement('situaciones', situacion)">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
				</div>
			</div>
		</div>

		<div v-if="producto.id" class="pt-4">
			<button @click="updateShopify()" class="btn btn-sm w-full btn-primary no-animation ">{{ "Actualizar Shopify" }}</button>
		</div>   

		<div class="pt-4">
			<button @click="crearProducto()" class="btn btn-sm w-full btn-primary no-animation ">{{ route.params.producto_id ? "Actualizar" : "Crear" }}</button>
		</div>    
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useRoute, useRouter } from 'vue-router'
	import axios from 'axios'
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'

	import utils from '../utils.js'

	export default {
		name: 'AdminProducto',
		setup() { 
			const route = useRoute()
			const router = useRouter()

			const loading = useLoading({
				loader: "spinner"
			});

			const perfil = ref("")
			const personalidad = ref("")
			const situacion = ref("")

			const producto = ref({
				proveedor: "",				
				meta: {},
				activo: false
			})
			const proveedores = ref([])

			let paises = ["México","Francia","Italia","España","Austria","Alemania","Australia","Portugal","Argentina","Nueva Zelanda","Sud Africa","Bretaña","Estados Unidos","Chile","Japón","Sudáfrica"]

			let perfiles = ["Mineral","Especiado","Amaderado","Floral ","Herbal","Frutos rojos","Frutos cítricos","Frutos tropicales","Ligero","Potente","Goloso","GluGlu","Intenso","Compotado","Picante","Ligeramente Frutal","Ligeramente compotado","Fruta madura","Fruta seca","Ligeramente amaderado","Frutos negros","Regalíz","Fresco","Terroso","Ligeramente fresco","Tostado","Láctico","Untuoso","Seco","Durazno","Pera","Frutas Blancas","Jugoso","Aromático","Vegetal","Balsámico","Ahumado","Ligeramente vegetal","Cereza","Fresa","Reducción ligera","Manzana","Miel","Violeta","Frutal","Mentoles","Chocolate amargo","Petróleo","Levadura","Crocante","Cuero","Tabaco","Chocolate con Leche","Crujiente","Astringente","Manzana verde","Pimienta Blanca","Melón","Piel de Toronja","Café","Té limón","Brioche","Maracuyá","Guayaba","Pimienta Negra","Chocolate","Piel de naranja","Manzana amarilla","Ligeramente Terroso","Frutos del Bosque","Ligeramente Floral","Aterciopelado","Fruta blanca","Fruta de la Pasión","Ligeramente Herbal","Litchi","Papaya","Animal","Piedra","Cera","Piel de limón","Albaricoque","Jengibre","Ligeramente especiado","Aceituna","Lima","Mantequilla","Ligeramente tropical","Hojarasca","Ligeramente mentolado","Roble","Vainilla","Casis","Mora","Nuez","Especias dulces","Grosella","Frambuesa","Canela","Flor de jamaica","Cacao","Fizzy","zest limon","Bolleria","Eucalipto","Manzanilla","Fruta cristalizada","Flores Blancas","Limón Amarillo","Fruta compotada","Zest de toronja","Rosas","Fruta verde","Oleoso","Pimiento","Fruta con hueso","Toque de nuez","Mentolado","Lichi","Cereza negra","Frutos Silvestres","Ciruela","Plátano","Frutos Secos","Melaza","Carambola","Musgo","Hojas Secas","Pimienta Roja","Fruta fresca","Especias dulces","Flores Rojas","Ligeramente fizzy","Pastry","Chile Seco","Jalea","Flores Amarillas","Avellana"]

			let personalidades = ["Jovial", "Serio", "Fresco", "Funky", "Sabio", "Cordial", "Aromático", "Rockero", "Intenso", "Refinado", "Versátil", "Modesto", "Extrovertido", "Ingenioso", "Introvertido", "Late Bloomer", "Oportuno", "Enigmático", "Generoso", "Desenfadado", "Juguetón", "Femenino", "Intrépido", "Goloso", "Alegre", "Audaz", "Romántico", "Interesante", "Elegante", "Easy going", "Ligero", "Crocante", "Salvaje", "Áspero", "Celebrar", "Persistente", "Formal", "Coqueto", "Sensible", "Espontáneo", "Cautivador", "Ecuánime", "Honesto", "Aventurero", "Complejo", "Rebelde", "Autentico", "Ambicioso", "Flexible", "Moderno", "Cosmopolita", "Seductor", "Pícaro", "Tierno", "Original", "Deseable", "Corpulento", "Jugoso", "Vibrante", "Intelectual", "Melancólico", "Amable", "Sedoso", "Fiestero", "Irreverente", "Innovador", "Gentil", "Complaciente", "Clásico", "Sorprendente", "Incomprendido", "Conservador", "Estóico", "Grosero", "Rascall", "Sin pretención", "Nostálgico", "Cariñoso", "Glu Glu", "Untuoso", "Cozy", "Singular", "Bohemio", "Delicado", "Crowd pleaser", "Simpático", "Brillante", "Potente", "Classy", "Carismático", "Inesperado", "Divertido", "Amigable", "Traveler", "Misterioso", "self date", "Old Soul", "Friendly", "Charming", "Social", "Contemplativo", "Unico", "Vintage", "Bookish", "Reflexivo", "Protagónic@"]

			let situaciones = ["Acompañar un Puro/Habano","Alberca","Aniversario","Aperitivo","Asado","Atardecer","Bosque","Botanear","Bruma","Brunch","Buen Libro","Buena cena","caminata en jardin","caminata en jardín","Carnita Asada","Celebrar","Cena Casual","Cena Familiar","Chick flick","Chimenea","Chismecito","Cita casual","Cita romantica","Comida casual","Comida Familiar","Con amigos especiales","Contemplación","Cotorrear","Crowd pleaser","Date Night","Debate","Día soleado","Dominguear","Entre amigos","Fiesta casual","Fogata","House warming","Juegos De Mesa","Last call","Llevar con tu mamá o tu abuelita","Lluvia","Loner","Me time","Montaña","Netflix","Offline","Para comer","Para leer","Para picar","Parrillada","Picnic","Pijamada","Platica entre amigos","Plática profunda","Playa","Postre","Precopeo","Rainy day","Regalo Especial","Romantico","Seductor","Self Date","Suegros","Taquiza","Terraza","Wow Factor"]

			async function getProveedores() {
				const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			} 

			async function getProducto(id) {
				const { data } = await supabase
				.from('productos')
				.select('*, proveedor(id, nombre)')
				.eq("id", id)
				.single()

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			} 

			async function updateShopify() {
				let loader = loading.show()

				await crearProducto(loader)

				axios({
					method: producto.value.shopify_id ? "PUT" : "POST",
					url: '/api/producto',
					data: {
						"shopify_id": producto.value.shopify_id,
						"name": producto.value.nombre,
						"type": producto.value.meta.tipo,
						"vendor": producto.value.proveedor.nombre,
						"price": producto.value.precio,						
						"meta": {
							"pais": producto.value.meta.pais,
							"region": producto.value.meta.region,
							"situacion": producto.value.meta.situaciones ? producto.value.meta.situaciones.join(", ") : "",
							"tanino": producto.value.meta.tanino,
							"acidez": producto.value.meta.acidez,
							"frutalidad": producto.value.meta.frutalidad,
							"cuerpo": producto.value.meta.cuerpo,
							"perfiles": producto.value.meta.perfiles,
							"personalidades": producto.value.meta.personalidades,
							"dato": producto.value.meta.dato
						}
					}
				}).then( async (data) => {
					console.log(data)
					loader.hide()

					if (!producto.value.shopify_id) {
						producto.value.shopify_id = data.data.product.id
						producto.value.shopify_handle = data.data.product.handle
						await crearProducto(loader)
					}

					//Need to set the shopify ID and hanldle and call again create

					useToast().success("Producto actualizado en Shopify", { position: "top-right" });			
				}).catch( e => {
					console.log(e)
					loader.hide()
					useToast().error("No jaló", { position: "top-right" });	
				}) 	
			}	

			function cleanObject(obj) {
				for (let key in obj) {
					if (Object.prototype.hasOwnProperty.call(obj, key)) {
						let value = obj[key];

						if (value === '' || value === undefined || (Array.isArray(value) && value.length === 0)) {
							delete obj[key];
						} else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
							cleanObject(value);
							if (Object.keys(value).length === 0) {
								delete obj[key];
							}
						}
					}
				}
				return obj;
			}

			async function crearProducto(show) { 
				if (!producto.value.proveedor) {
					return alert("Requiere proveedor")
				}

				if (producto.value.costo == null) {
					return alert("Requiere costo")
				}

				if (!producto.value.precio) {
					return alert("Requiere precio")
				}

				if (!producto.value.nombre) {
					return alert("Requiere nombre")
				}

				let loader; 

				if (show) {
					loader = show
				} else {
					loader = loading.show()
				}

				let p = { 					
					nombre: producto.value.nombre,
					costo: producto.value.costo,
					precio: producto.value.precio,
					activo: producto.value.activo,
					proveedor: producto.value.proveedor.id,
					shopify_id: producto.value.shopify_id,
					photo: producto.value.photo,
					shopify_handle: producto.value.shopify_handle,
					meta: {
						tipo: producto.value.meta.tipo,
						pais: producto.value.meta.pais,
						region: producto.value.meta.region,
						situacion: producto.value.meta.situaciones ? producto.value.meta.situaciones.join(", ") : "",
						tanino: producto.value.meta.tanino,
						cuerpo: producto.value.meta.cuerpo,
						acidez: producto.value.meta.acidez,
						frutalidad: producto.value.meta.frutalidad,
						perfiles: producto.value.meta.perfiles,
						personalidades: producto.value.meta.personalidades,
						dato: producto.value.meta.dato
					}
				}

				if (producto.value.id) {
					p.id = producto.value.id
				}

				let cleanP = cleanObject(p)				

				const { data, error } = await supabase.from('productos').upsert(cleanP).select('*, proveedor(id, nombre)').single();		

				console.log(data)
				if (error) {
					loader.hide()
					console.log(error)
					// return alert ("Necesitas nombre, precio, costo")
				} 

				if (!route.params.producto_id && data) {
					producto.value = data
					router.push(`/producto/${data.id}`)
				}	

				if (!show){
					loader.hide()
				}
				useToast().success(route.params.producto_id ? "Producto actualizar" : "Producto creado" , { position: "top-right" });			
			}

			onMounted( async () => {  
				proveedores.value = await getProveedores()

				if (route.params.producto_id) {
					producto.value = await getProducto(route.params.producto_id)
					if (!producto.value.proveedor) {
						producto.value.proveedor = ""
					}

					if (!producto.value.meta || producto.value.meta == {}) {
						producto.value.meta = {
							perfiles: [],
							personalidades: [],
							situaciones: [],
							tipo: ""
						}
					}
				} else {
					producto.value.meta = {
						perfiles: [],
						personalidades: [],
						situaciones: [],
						tipo: ""
					}
				}
			})

			function validateQuantity(key, value) {
				console.log(value)
				if (value < 0) {
					producto.value.meta[key] = 0;
				} else if (value > 5) {
					producto.value.meta[key] = 5;
				}
			}

			function addElement(element, value) {	

				if (value == "XNUEVOX") {
					let newValue = prompt(`¿Cual ${element} te gustaría agregar?`, "");

					if (newValue == null || newValue == "") {
						return 
					} else {
						return addElement(element, newValue)
					}
				}

				if (element == "situaciones") {
					situacion.value = ""
				} else if (element == "personalidades") {
					personalidad.value = ""
				} else if (element == "perfiles") {
					perfil.value = ""
				}

				if (!producto.value.meta[element]) {
					producto.value.meta[element] = []
				}		

				if (!producto.value.meta[element].includes(value))	{
					producto.value.meta[element].push(value)
				}
			}

			function removeElement(element, value) {
				if (element == "situaciones") {
					situacion.value = ""
				} else if (element == "personalidades") {
					personalidad.value = ""
				} else if (element == "perfiles") {
					perfil.value = ""
				}
				producto.value.meta[element] = producto.value.meta[element].filter(function(item) {
					return item !== value;
				});
			}

			function importMeta() {
				let loader = loading.show()
				axios({
					method: "GET",
					url: '/api/producto',
					params: {
						"shopify_id": producto.value.shopify_id,						
					}
				}).then( async (data) => {
					console.log(data)
					let metadata = JSON.parse(data.data.metafields[0].value)

					console.log(metadata)

					producto.value.meta = {					
						pais: metadata.Pais,
						region: metadata.Region,
						situaciones: metadata.Situacion.split(", "),
						tanino: metadata.Tanino,
						cuerpo: metadata.Cuerpo,
						acidez: metadata.Acidez,
						frutalidad: metadata.Frutalidad,
						perfiles: metadata.Perfiles,
						personalidades: metadata.Personalidades,
						dato: metadata.Dato
					}	

					console.log(producto)

					loader.hide()
					useToast().success("Metadata importado de Shopify", { position: "top-right" });			
				}).catch( e => {
					console.log(e)
					loader.hide()
					useToast().error("No jaló", { position: "top-right" });	
				}) 
			}

			async function uploadFile(evt) {
				const uploadField = document.getElementById("product_foto");				

				if (uploadField.files[0].size > 2097152) {
					return useToast().warning('The maximum size for a photo is 2mb, please select a smaller file.', { position: "top-right" }); 
				}				

				let loader = loading.show()
				let fileName = await utils.uploadFileHandler('photos', evt)				
				loader.hide()

				let url = `https://eekmegyvggirtnebrupv.supabase.co/storage/v1/object/public/${fileName}`
				
				producto.value.photo = url				
			}

			return {
				route,
				producto,
				proveedores,
				crearProducto,
				paises,
				perfiles,
				personalidades,
				situaciones,
				validateQuantity,
				addElement,
				removeElement,
				perfil,
				personalidad,
				situacion,
				updateShopify,
				importMeta,
				uploadFile
			}
		}
	}
</script>