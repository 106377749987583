<template>
	<div class="p-4">
		<div class="flex justify-between">			
			<p class="text-2xl">Prospectos</p>						
		</div>

		<div class="flex flex-row cursor-pointer w-full mt-4">
			<div @click="filter = 'todo'" :class="[filter == 'todo' ? 'opacity-100 bg-white text-black' : 'opacity-50 border border-1 border-r-0']" class="flex items-center justify-center w-full text-lg bg-base-100 rounded-l-lg">
				<p>Todo</p>
			</div>
			<div @click="filter = 'por_contactar'" :class="[filter == 'por_contactar' ? 'opacity-100 bg-white text-black' : 'opacity-50 border border-l-0 border-r-0']" class="flex items-center justify-center w-full text-lg bg-base-100">
				<p>Por contactar</p>
			</div>	
			<div @click="filter = 'por_cerrar'" :class="[filter == 'por_cerrar' ? 'opacity-100 bg-white text-black' : 'opacity-50 border border-1 border-l-0']" class="flex items-center justify-center w-full text-lg bg-base-100 rounded-r-lg">
				<p>Por cerrar</p>
			</div>	
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th v-if="filter == 'por_cerrar'"></th>
						<th class="w-1/12">Fecha</th>
						<th class="w-1/12">Nombre</th>
						<th class="w-2/12">Número tel.</th>
						<th class="w-1/12">Correo</th>
						<th class="w-1/12">Estado</th>
						<th class="w-1/12">Club</th>	
						<th class="w-1/12">Estatus</th>							
						<th class="w-2/12">Notas</th>		
						<th class="w-1/12">Suscrito</th>																
						<th class="w-1/12">Acción</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="prospecto in prospectosFiltrados" v-bind:key="prospecto.id">
						<td v-if="filter == 'por_cerrar'">
							<input class="checkbox no-animation" type="checkbox" v-model="prospecto.blast"/>
						</td>
						<td>
							<p>{{prospecto.created_at.split("T")[0]}}</p>
						</td>	
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.nombre}}</p>
							<input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.nombre"/>
						</td>		
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.tel}}</p>
							<input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.tel"/>
						</td>		
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.correo}}</p>
							<input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.correo"/>
						</td>		
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.estado}}</p>
							<!-- <input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.estado"/> -->
							<select v-if="edit[prospecto.id]" v-model="prospecto.estado" className="select select-sm select-block select-bordered w-full">		
								<option v-for="estado in estados" v-bind:key="estado" :value="estado"> {{estado}} </option>
							</select>	
						</td>				
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.club}}</p>
							<select v-if="edit[prospecto.id]" v-model="prospecto.club" className="select select-sm select-block select-bordered w-full">		
								<option value="Tinto"> Tinto </option>
								<option value="Blanco"> Blanco </option>
								<option value="Explorer"> Explorer </option>
								<option value="Fearless"> Fearless </option>
								<option value="Bold"> Bold </option>
								<option value="Bright"> Bright </option>
							</select>
						</td>
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.estatus}}</p>
							<select v-if="edit[prospecto.id]" v-model="prospecto.estatus" className="select select-sm select-block select-bordered w-full">		
								<option value="Contacto"> Contacto </option>
								<option value="Perdido"> Perdido </option>
								<option value="Cerrado nosotros"> Cerrado nosotros </option>
								<option value="Cerrado solito"> Cerrado solito </option>
								<option value="Duplicado"> Duplicado </option>
							</select>				
						</td>	
						<td>
							<p v-if="!edit[prospecto.id]">{{prospecto.notas}}</p>
							<input v-if="edit[prospecto.id]" class="input input-bordered input-sm w-full" placeholder="" type="text" inputmode="text" v-model="prospecto.notas"/>
						</td>
						<td>
							{{hasClub(prospecto.correo) ? "Si" : "No"}}
						</td>
						<td>	
							<div class="flex flex-row space-x-4 w-full">
								<button @click="mandarMensaje(prospecto)" class="btn btn-sm btn-primary no-animation w-full flex-1">Mandar</button>					
								<button v-if="!edit[prospecto.id]" @click="edit[prospecto.id] = true" class="btn btn-primary btn-sm no-animation w-full flex-1">Editar</button>
								<button v-if="edit[prospecto.id]"  @click="updateProspecto(prospecto)" class="btn btn-primary btn-sm no-animation w-full flex-1">Guardar</button>
								<div v-if="edit[prospecto.id]" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="cancelEdit(prospecto);">
									<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 w-8">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div v-if="filter == 'por_cerrar'" class="w-full pt-4">
			<p class="text-sm pb-2">Template</p>
			<select v-model="template" class="select select-sm select-bordered w-full">							
				<option selected disabled :value="null">Escoger un template</option>	
				<option v-for="template in templates" v-bind:key="template.elementName" :value="template.elementName"> {{ template.elementName }} </option>						
			</select> 
		</div>

		<button v-if="filter == 'por_cerrar'" @click="blast()" class="mt-4 btn btn-primary btn-sm no-animation w-full">Mandar oferta</button>
	</div>	
</template>
<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"	
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import axios from "axios";

	export default {
		name: 'AdminCatas',
		setup() { 			
			let prospectos = ref([])
			let prospectosCopy = ref([])			
			let clubs = ref([])

			let edit = ref({})

			let filter = ref("todo")

			const loading = useLoading({
				loader: "spinner"
			});

			const template = ref(null)
			const templates = ref([])

			const estados = ref(["Aguascalientes",
				"Baja California",
				"Baja California Sur",
				"Campeche",
				"Chiapas",
				"Chihuahua",
				"Ciudad de México",
				"Coahuila",
				"Colima",
				"Durango",
				"Estado de México",
				"Guanajuato",
				"Guerrero",
				"Hidalgo",
				"Jalisco",
				"Michoacán",
				"Morelos",
				"Nayarit",
				"Nuevo León",
				"Oaxaca",
				"Puebla",
				"Querétaro",
				"Quintana Roo",
				"San Luis Potosí",
				"Sinaloa",
				"Sonora",
				"Tabasco",
				"Tamaulipas",
				"Tlaxcala",
				"Veracruz",
				"Yucatán",
				"Zacatecas"])

			async function getProspectos() {
				const { data, error } = await supabase
				.from('prospectos')
				.select('*')				
				.order('created_at', { ascending: false }) 			

				console.log(data, error)
				if (data) {
					return data
				} else if (error) {
					console.log(error)
				} else {
					alert("No data returned")
				}
			}				

			async function getClubs() {	
				const { data, error } = await supabase
				.from('suscripciones')
				.select('*, cliente(correo)')
				.eq('estatus', 'activo')

				if (error) {
					console.error(error);
				} else {
					return data.map( c => c.cliente.correo)
				}			
			}

			function hasClub(correo) {
				return clubs.value.includes(correo)
			}

			async function updateProspecto(prospecto) {
				if(!confirm("¿Quieres guardar tus cambios?")){
					return cancelEdit(prospecto)
				}		

				const { error } = await supabase.from('prospectos').upsert(prospecto);	

				if (error) {
					return alert (error.details)
				}

				prospectosCopy.value = structuredClone(prospectos.value)

				useToast().success("Prospecto actualizada", { position: "top-right" });	

				edit.value[prospecto.id] = false
			}

			function formatDateEuroStyle(fecha) {
				let components = fecha.split("-")

				return `${components[2]}/${components[1]}/${components[0]}`
			}

			const prospectosFiltrados = computed( () => {
				return prospectos.value.filter( p => {
					
					switch (filter.value) {
					case "por_contactar":
						return !p.estatus 
					case "por_cerrar":
						return p.estatus != "Cerrado solito" && p.estatus != "Cerrado nosotros" && p.estatus != "Perdido" && p.estatus != "Duplicado"	
					default:
						return true						
					}

				})
			})

			function cancelEdit(prospecto) {
				edit.value[prospecto.id] = false
				prospectos.value = structuredClone(prospectosCopy.value)			
			}
			

			onMounted( async () => {  				
				getProspectos().then( data => {
					prospectos.value = data
					prospectosCopy.value = JSON.parse(JSON.stringify(data))
				})		

				getClubs().then( data => {
					clubs.value = data
				})				

				getTemplates().then( data => {				
					templates.value = data.messageTemplates.filter( t => t.status == "APPROVED" && t.elementName.includes('blast'))	
				})
			})

			async function mandarMensaje(prospecto) {					
				if(!confirm("¿Quieres mandar el mensaje?")){
					return 
				}	

				if(!prospecto.estado){
					return alert('No tiene estado')
				}

				let loader = loading.show()

				let template = ""

				if (prospecto.estado == "Ciudad de México" || prospecto.estado == "Estado de México") {
					template = "prospecto_local_v2"
				} else {
					template = "prospecto_foraneo"
				}

				let { status } = await axios.post("/api/blast", {
					"template_name": template,
					"broadcast_name": `prospecto_${prospecto.tel}`,
					"receivers": [{
						"whatsappNumber": `${prospecto.tel.replaceAll(" ","")}`,
						"customParams": [{
							"name": "nombre",
							"value": prospecto.nombre.split(" ")[0]
						},{
							"name": "club",
							"value": prospecto.club
						}]				
					}]
				})

				loader.hide()

				if (status == 200) {
					useToast().success("Mensaje mandado", { position: "top-right" });	
				} else {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				}				
			}			

			async function getTemplates() {
				let { data }  = await axios.get("/api/templates")		

				return data			
			}	

			async function blast() {								
				if (!confirm('¿Estás seguro del blast?')) {
					return
				} 

				if (!template.value) {
					return alert('Escoge un template')
				} 

				let loader = loading.show()

				let prospectosToBlast = prospectosFiltrados.value.filter( p => p.blast ).map( p => {
					return {
						"whatsappNumber": `${p.tel.replaceAll(" ","")}`,		
						"customParams": [{
							"name": "nombre",
							"value": p.nombre.split(" ")[0]
						}]				
					}
				})

				console.log(prospectosToBlast)				

				let { status }  = await axios.post("/api/blast", {
					"template_name": template.value,
					"broadcast_name": 'blast_prospectos',
					"receivers": prospectosToBlast										
				})	

				loader.hide()

				if (status == 200) {
					useToast().success("Mensajes mandado", { position: "top-right" });	
				} else {
					useToast().error("Hubo un error, pregúntale a Noah", { position: "top-right" });	
				}

				// console.log(status)
			}

			return {								
				prospectosCopy,
				edit,	
				cancelEdit,
				updateProspecto,
				prospectosFiltrados,
				formatDateEuroStyle,
				filter,
				mandarMensaje,
				hasClub,
				estados,
				blast,
				template,
				templates
			}
		}
	}
</script>