<template>
	<div class="p-4 text-center">
		<p class="text-xl">!Gracias!</p>
		<p>Ya quedó registrado</p>
	</div>
</template>	

<script>
	export default {
		name: 'AdminGracias',
		setup() {				
			return {
				
			}
		}
	}
</script>