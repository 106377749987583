import { supabase } from "./supabase"
import axios from "axios";

async function mandarMensaje(cliente, mensaje, params) {	
	let { status } = await axios.post("/api/blast", {
		"template_name": mensaje.template,
		"broadcast_name": `mensaje_${mensaje.template}_${cliente.id}`,
		"receivers": [{
			"whatsappNumber": `${cliente.country_code.replace(/\D/g, "")}1${cliente.telefono}`,
			"customParams": params
		}]
	})

	if (status == 200) {
		return (true)		
	} else {
		return (false)
	}
}			


async function addMessage(mensaje, cliente) {	
	let mensajeToInsert = {
		template: mensaje.template,
		nombre: mensaje.nombre,
		cliente: cliente.id
	}

	const { error } = await supabase.from('mensajes').insert(mensajeToInsert).select().single()

	
	if (error) {		
		return error
	} 

	return null	
}

async function uploadFileHandler(type, evt) {  
    console.log(type, evt)

    if (!evt.target.files || evt.target.files.length === 0) {
        throw new Error("Selecciona un archivo")
    }
    const file = evt.target.files[0]
    const fileExt = file.name.split(".").pop()
    const fileName = `${Math.random()}.${fileExt}`
    const filePath = `${fileName}`

    return await uploadFile(type, filePath, file)                     
}

async function uploadFile(type, filePath, file) {
    let { data, error } = await supabase.storage
    .from(type)
    .upload(filePath, file)                         

    console.log("uploadFile", data, error)
    if (data && data.fullPath) {           
        return data.fullPath             
    } else if (error) {  
        console.log("utils uploadFile", error)
        throw error          
    } 
}

export default {
	mandarMensaje: mandarMensaje,
	addMessage: addMessage,
	uploadFileHandler: uploadFileHandler
}