<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Salidas (Por Ubicación)</p>
			<div class="">
				<button @click="generateCSV()" class="btn btn-primary btn-sm no-aniation">CSV</button>
			</div>  
		</div>
		
		<div class="pt-4 w-full">       
			<select v-model="proveedor" class="select select-sm select-bordered w-full">				
				<option selected value="">Todos</option>
				<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor"> {{ proveedor.nombre }} </option>
			</select>
		</div>

		<div class="flex space-x-4 mt-2">
		<input class="input  input-bordered input-sm w-full" v-model="startDate" type="date">

		<input class="input  input-bordered input-sm w-full"  v-model="endDate" type="date">
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr class="">
						<th>Proveedor</th>
						<th>Producto</th>
						<th v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id">{{ubicacion.nombre}}</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody class="">
					<tr v-for="producto in filteredProductos" v-bind:key="producto.id">
						<th>{{producto.proveedor ? producto.proveedor.nombre : ""}}</th>		
						<th>{{producto.nombre}}</th>		
						<td v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id"> {{getTotal(producto, ubicacion.id)}} </td>
						<td> {{getTotal(producto)}} </td>						
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"

	export default {
		name: 'AdminVentas',
		setup() {				
			const ubicaciones = ref(['Todos'])
			const productos = ref([])
			const proveedores = ref([])
			const proveedor = ref("")
			const startDate = ref("")
			const endDate = ref("")

			function getTotal(producto, ubicacion) {	
				return getSalidas(producto, ubicacion) 
			}

			function getSalidas(producto, ubicacion) {
				let salidas = producto.salidas.filter(a => ubicacion ? a.ubicacion == ubicacion : true)


				if (startDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) > timeShift(startDate.value, 6))
				} 

				if (endDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) < timeShift(endDate.value, 24))
				} 				

				return salidas.length > 0 ? salidas.map(s => s.cantidad).reduce((prev, next) => prev + next) : 0
			}			

			function getSeconds(date) {
				let seconds = new Date(date)
				return seconds.getTime()
			}	

			function timeShift(date, hours) {				
				let beforeShift = new Date(date) 			
				let afterShift = new Date(beforeShift.getTime() + ((6 + hours)*60*60*1000));				
				return afterShift
			}

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}

			async function getProveedores() {
				const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			}			

			async function getProductos() {
				let query = supabase
				.from('productos')
				.select('id, nombre, shopify_handle, salidas (*, shopify_id), proveedor (id, nombre)')
				.not( "salidas.shopify_id", "is", "null")                       

				//4786273026107					

				const { data } = await query

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	
			
			function generateCSV() {
				let csv = "Proveedor,Title," + ubicaciones.value.map(u => u.nombre) + ",Total\n";

				filteredProductos.value.forEach((p) => {
					let a = [p.proveedor ? p.proveedor.nombre.replaceAll(",","") : " ",p.nombre.replaceAll(",","")]
					let b = ubicaciones.value.map(u => getTotal(p, u.id))
					let c = [getTotal(p), "\n"]
					csv += a.concat(b).concat(c)
				});

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'ventas.csv';
				anchor.click();
				
			}

			const filteredProductos = computed( () => {
				return productos.value.filter( p => proveedor.value ? p.proveedor && p.proveedor.id == proveedor.value.id : true )
			})

			onMounted( async () => {					
				ubicaciones.value = await getUbicaciones()
				productos.value = await getProductos()
				proveedores.value = await getProveedores()
			})

			return {				
				ubicaciones,
				productos,				
				getSalidas,
				getTotal,
				getProductos,
				generateCSV,
				proveedor,
				proveedores,
				filteredProductos,
				startDate,
				endDate
			}
		}
	}
</script>