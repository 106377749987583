<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Extracciones</p>

			
			<div class="flex items-center space-x-4">
				<div>Min:</div>
				<input class="input input-bordered input-sm w-24" placeholder="Min" type="number" v-model="min"/>
				<button @click="generateCSV()" class="btn btn-primary btn-sm no-aniation">CSV</button>
			</div>  
		</div>			

		<div class="flex space-x-4 mt-2">
			<input class="input  input-bordered input-sm w-full" v-model="startDate" type="date">

			<input class="input  input-bordered input-sm w-full"  v-model="endDate" type="date">
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table id="table" class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr class="">
						<th class="w-1/12">Proveedor</th>
						<th class="w-4/12">Producto</th>
						<!-- <th v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id">{{ubicacion.nombre}}</th> -->
						<th class="w-2/12">Ventas</th>
						<!-- <th v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id">{{ubicacion.nombre}}</th> -->
						<th class="w-2/12">Inventario</th>
						<!-- <th class="w-2/12">Apartado</th> -->
						<th class="w-2/12">Almacen Fiscal</th>
						<th class="w-1/12">Extraer</th>
						
					</tr>
				</thead>
				<tbody class="">
					<tr v-for="producto in filteredProductos" v-bind:key="producto.id">
						<th>{{producto.proveedor ? producto.proveedor.nombre : ""}}</th>		
						<th>{{producto.nombre}}</th>		
						<!-- <td v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id"> {{getVentasTotal(producto, ubicacion.id)}} </td> -->
						<td> {{getVentasTotal(producto)}} </td>	
						<!-- <td v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id"> {{ inventory[producto.shopify_handle] ? inventory[producto.shopify_handle][ubicacion.id] || 0 : 0}} </td> -->
						<!-- <td> {{ inventory ? inventory[producto.shopify_handle] ? inventory[producto.shopify_handle][8] || 0 : 0 : ""}} </td>	 -->
						<td> {{ inventory ? inventory[producto.shopify_handle] ? getStockActual(producto) || 0 : 0 : ""}} </td>											
						<td> {{ inventory ? inventory[producto.shopify_handle] ? inventory[producto.shopify_handle][9] || 0 : 0 : ""}} </td>	
						<td> {{ extraer(producto) }}</td>						
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"

	export default {
		name: 'AdminVentas',
		setup() {				
			const ubicaciones = ref(['Todos'])
			const productos = ref([])
			const proveedores = ref([])
			const inventory = ref([])
			const proveedor = ref("")
			const startDate = ref("")
			const endDate = ref("")
			const min = ref(24)

			function getStockActual(producto) {
				if (!inventory.value[producto.shopify_handle]) {
					return 0
				}

				let ids = [4, 2, 3, 5, 13]

				let stock = 0

				ids.forEach( id => {
					stock = stock + inventory.value[producto.shopify_handle][id]
				}) 

				return stock
			}

			function getVentasTotal(producto, ubicacion) {	
				return getSalidas(producto, ubicacion) 
			}

			function getSalidas(producto, ubicacion) {
				let salidas = producto.salidas.filter(a => ubicacion ? a.ubicacion == ubicacion : true)


				if (startDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) > timeShift(startDate.value, 6))
				} 

				if (endDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) < timeShift(endDate.value, 24))
				} 				

				return salidas.length > 0 ? salidas.map(s => s.cantidad).reduce((prev, next) => prev + next) : 0
			}			

			function getSeconds(date) {
				let seconds = new Date(date)
				return seconds.getTime()
			}	

			function timeShift(date, hours) {				
				let beforeShift = new Date(date) 			
				let afterShift = new Date(beforeShift.getTime() + ((6 + hours)*60*60*1000));				
				return afterShift
			}

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 
				.in('id', [4, 2, 3, 5, 13])

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}

			async function getProveedores() {
				const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			}			

			async function getProductos() {
				let query = supabase
				.from('productos')
				.select('id, nombre, shopify_handle, salidas (*, shopify_id), proveedor (id, nombre)')
				.not( "salidas.shopify_id", "is", "null")    
				.order('nombre', { ascending: true })      
				.in('proveedor', ['9', '10'])             			

				const { data } = await query

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	
			
			function generateCSV() {
				const table = document.getElementById("table");
				let csv = [];

				for (let row of table.rows) {
					let rowData = [];
        
					for (let cell of row.cells) {
						rowData.push(cell.innerText.replaceAll(",",""));
					}
					csv.push(rowData.join(","));
				}

				const csvContent = csv.join("\n");

				const blob = new Blob([csvContent], { type: 'text/csv' });
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement("a");
				a.setAttribute("href", url);
				a.setAttribute("download", 'extraccion.csv');
				a.style.display = "none";
				document.body.appendChild(a);
				a.click();

				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}

			const filteredProductos = computed( () => {
				return productos.value.filter( producto => {
					if (!producto.shopify_handle) {
						return
					}

					if (!inventory.value[producto.shopify_handle]) {
						return
					}
					
					return (inventory.value[producto.shopify_handle][9] > 0)
				})
			})

			function extraer(producto) {
				if (!producto.shopify_handle) {
					return inventory.value[producto.shopify_handle][2] + inventory.value[producto.shopify_handle][2]
				}

				if (!inventory.value[producto.shopify_handle]) {
					return
				}

				if (getVentasTotal(producto) == 0 && getStockActual(producto) == 0 && inventory.value[producto.shopify_handle][9] > 0) {
					if (min.value > inventory.value[producto.shopify_handle][9]) {
						return inventory.value[producto.shopify_handle][9]
					} else {
						return min.value
					}
				}

				if (getVentasTotal(producto) > getStockActual(producto) && inventory.value[producto.shopify_handle][9] > 0) {
					let toExtraer = getVentasTotal(producto) - getStockActual(producto)

					if (toExtraer > inventory.value[producto.shopify_handle][9]) {
						return inventory.value[producto.shopify_handle][9]
					} else if (toExtraer < min.value) {
						if (min.value > inventory.value[producto.shopify_handle][9]) {
							return inventory.value[producto.shopify_handle][9] 
						} else {
							return min.value
						}
					} else {
						return toExtraer
					}
				}
			}

			onMounted( async () => {	
				startDate.value = getDateDaysAgo(31)
				endDate.value = getDateDaysAgo(1)		

				getUbicaciones().then( d => {
					ubicaciones.value = d
				})

				getProductos().then( d => {
					productos.value = d
				})

				getProveedores().then( d => {
					proveedores.value = d
				})

				getInventory().then( d => {
					inventory.value = d
				})								
			})

			async function getInventory() {
				const groupedObjects = {};

				const { data } = await supabase.rpc('total_inventory')	

				data.forEach(producto => {					
					if (Object.prototype.hasOwnProperty.call(groupedObjects, producto.shopify_handle)) {						
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock	
						groupedObjects[producto.shopify_handle].total += producto.stock								
					} else {						
						groupedObjects[producto.shopify_handle] = {};
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock	
						groupedObjects[producto.shopify_handle].total = producto.stock							
					}
				});

				console.log("groupedObjects", groupedObjects)
				return groupedObjects;
			}	

			function getDateDaysAgo(daysAgo) {
				return new Date(new Date().setDate(new Date().getDate() - daysAgo)).toISOString().split('T')[0];
			}

			return {				
				ubicaciones,
				productos,	
				inventory,			
				getSalidas,
				getVentasTotal,
				getProductos,
				generateCSV,
				proveedor,
				proveedores,
				filteredProductos,
				startDate,
				endDate,
				extraer,
				min,
				getStockActual
			}
		}
	}
</script>