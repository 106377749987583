<template>
	<div class="p-4">
		<p class="text-2xl">Transfers</p>
		
		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th>Fecha</th>		
						<th>Desde</th>
						<th>Hacia</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="transfer in transfers" v-bind:key="transfer.id">
						<th><router-link :to="`transfer/${transfer.id}`">{{transfer.created_at.split("T")[0]}}</router-link></th>		
						<th>{{transfer.from.nombre}}</th>		
						<th>{{transfer.to.nombre}}</th>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminTransfers',
		setup() {	
			const transfers = ref([])
			
			async function getTransfers() {
				const { data } = await supabase
				.from('transfers')
				.select('id, created_at, from (nombre), to (nombre)')							
				.order('created_at', { ascending: false })	

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}	
			
			onMounted( async () => {					
				transfers.value = await getTransfers()				
			})

			return {
				transfers
			}
		}
	}
</script>