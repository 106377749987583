<template>
	<div class="p-4">
		<div class="justify-between flex">			
			<div>
				<p class="text-2xl">Catas</p>	
			</div>
			
			<div class="flex space-x-4 w-32" :class="[viewOnly ? 'justify-end' : 'justify-between']">			
				<div v-if="!viewOnly && view == 'Catas'" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="generateCSV()">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
					</svg>
				</div>

				<div v-if="!viewOnly && view == 'Catas'" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="toggle()">
					<svg v-if="!showNewCatas" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
					</svg>
					<svg v-if="showNewCatas" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
				</div>

				<div v-if="view == 'Catas'" class="border w-8 h-8 rounded-lg flex items-center justify-center cursor-pointer" @click="expand = !expand" >				
					<svg v-if="!expand" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
					</svg>
					<svg  v-if="expand" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
					</svg>
				</div>
			</div>
		</div>

		<div class="flex flex-row cursor-pointer w-full mt-4">
				<div @click="view = 'Catas'" :class="[view == 'Catas' ? 'opacity-100 bg-white text-black' : 'opacity-50 border border-1 border-r-0']" class="flex items-center justify-center w-full text-lg bg-base-100 rounded-l-lg">
					<p>Catas</p>
				</div>			
				<div @click="view = 'Reservas'" :class="[view == 'Reservas' ? 'opacity-100 bg-white text-black' : 'opacity-50 border border-1 border-l-0']" class="flex items-center justify-center w-full text-lg bg-base-100 rounded-r-lg">
					<p>Reservas</p>
				</div>			
			</div>

		<div v-if="view == 'Reservas'">
			<select v-model="week" className="select select-sm select-block select-bordered w-full mt-4">									
				<option v-for="week in weeks" v-bind:key="week" :value="week"> Semana {{ week }} </option>
			</select>	

			<select v-model="reserva_type" className="select select-sm select-block select-bordered w-full mt-4">									
				<option value="todo"> Todo </option>
				<option value="publico"> Público </option>
				<option value="waitlist"> Waitlist </option>
				<option value="primera_vez"> Primera vez </option>
				<option value="cancelado"> Cancelado </option>
			</select>	

			<div >
				<div class="w-full overflow-hidden my-4 overflow-x-scroll">
					<table class="table table-xs">
						<thead class="sticky bg-base-100 top-0">
							<tr>
								<th class="w-2/12">Nombre</th>
								<th class="w-2/12">Número tel.</th>
								<th class="w-1/12">Acompañantes</th>
								<th class="w-1/12">Primara vez</th>
								<th class="w-2/12">Estatus</th>	
								<th class="w-1/12">Tipo</th>	
								<th class="w-3/12">Notas</th>								
							</tr>
						</thead>
						<tbody >
							<tr v-for="reserva in reservas" v-bind:key="reserva.id" class="cursor-pointer">
								<td>
									<p v-if="reserva.cliente">{{reserva.cliente.nombre}} {{reserva.cliente.apellido}}</p>
									<p v-if="!reserva.cliente">{{reserva.cliente_texto}}</p>
								</td>		
								<td>
									<p v-if="reserva.cliente">{{reserva.cliente.country_code}} {{reserva.cliente.telefono}}</p>
									<p v-if="!reserva.cliente">{{reserva.numero}}</p>
								</td>		
								<td>
									<p >{{reserva.adicionales}}</p>							
								</td>	
								<td>
									<p >{{reserva.primera_vez}}</p>							
								</td>						
								<td>
									<p>{{reserva.estatus}}</p>		
								</td>
								<td>
									<p>{{reserva.type}}</p>
								</td>	
								<td>
									<p>{{reserva.notas}}</p>
								</td>						
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div v-if="view == 'Catas'">
			<div v-if="showNewCatas" class="pt-4 space-y-4">
				<div class="flex flex-row w-full space-x-4 ">
					<input class="input input-bordered input-sm basis-2/5" type="date" v-model="startDate"/>
					<input class="input input-bordered input-sm basis-2/5" type="date" v-model="endDate"/>
					<button @click="createCatas(startDate, endDate)" class="btn btn-primary btn-sm no-animation basis-1/5">Crear</button>
				</div>
			</div>

			<select v-model="week" className="select select-sm select-block select-bordered w-full mt-4">									
				<option v-for="week in weeks" v-bind:key="week" :value="week"> Semana {{ week }} </option>
			</select>		


			<div class="flex flex-row space-x-4">
				<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p class="text-xl">Pendientes</p>
					{{catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Pendiente").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0)}}	
				</div>

				<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p class="text-xl">Confirmados</p>
					{{catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0)}}	
				</div>

				<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p class="text-xl">Waitlist</p>
					{{catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Waitlist").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0)}}	
				</div>
			</div>

			<div class="flex flex-row space-x-4">
				<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p class="text-xl">Nuevos miembros</p>
					{{catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Confirmado" && r.primera_vez == true).map( r => 1 ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0)}}	
				</div>

				<div class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p class="text-xl">Miembros totales</p>
					{{catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Confirmado").map( r => 1 ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0)}}	
				</div>

				<div  class="w-full mt-4 border border-1 border-slate-700 rounded-lg p-2">
					<p  class="text-xl">Utilization</p>
					<p v-if="catasFiltradas.length > 0">{{ Math.round((catasFiltradas.map(  c => {
						return c.reservas.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)
					}).reduce((a, b) => a + b, 0) / catasFiltradas.filter( c => !c.cancelado ).map( c => c.ubicacion.capacidad ).reduce((a, b) => a + b, 0))*100)}}%</p>
				</div>

			</div>

			<div v-if="!expand">
				<div class="flex w-full pt-4 overflow-x-scroll">
					<table class="table table-xs">
						<thead class="sticky bg-base-100 top-0">
							<tr>
								<th class="">Fecha</th>
								<th class="">Día</th>
								<th class="">Ubicación</th>
								<th class="">Estatus</th>
								<th class="">Pendientes</th>
								<th class="">Confirmados</th>
								<th class="">Waitlist</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="cata in catasFiltradas" v-bind:key="cata.id" class="cursor-pointer" @click="goToCata(cata.id)">
								<td>{{formatDateEuroStyle(cata.fecha)}}</td>	
								<td>{{daysOfWeek[(new Date(cata.fecha)).getDay()+1]}}</td>
								<td>{{cata.ubicacion.nombre}}</td>
								<td>{{cata.cancelado ? "Cancelado" : "Activo"}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Pendiente").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Waitlist").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-if="expand" >
				<div class="w-full p-2 border border-1 border-slate-700 rounded-lg overflow-hidden my-4 cursor-pointer" v-for="cata in catasFiltradas" v-bind:key="cata.id" @click="goToCata(cata.id)">
					<table class="table table-xs overflow-x-scroll">
						<thead class="sticky bg-base-100 top-0">
							<tr>
								<th class="w-1/3">Fecha</th>
								<th class="w-1/3">Día</th>
								<th class="w-1/3">Ubicación</th>
								<th class="w-1/6">Pendientes</th>
								<th class="w-1/6">Confirmados</th>
								<th class="w-1/6">Waitlist</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{formatDateEuroStyle(cata.fecha)}}</td>	
								<td>{{daysOfWeek[(new Date(cata.fecha)).getDay()+1]}}</td>
								<td>{{cata.ubicacion.nombre}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Pendiente").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Confirmado").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
								<td>{{cata.reservas.filter( r => r.estatus == "Waitlist").map( r => 1 + r.adicionales ).reduce((a, b) => a + b, 0)}}</td>
							</tr>					
						</tbody>
					</table>


					<table class="table table-xs mt-4">
						<thead class="sticky bg-base-100 top-0">
							<tr>
								<th class="w-2/12">Nombre</th>
								<th class="w-2/12">Número tel.</th>
								<th class="w-1/12">Acompañantes</th>
								<th class="w-1/12">Primera vez</th>
								<th class="w-2/12">Estatus</th>	
								<th class="w-1/12">Tipo</th>	
								<th class="w-3/12">Notas</th>										
							</tr>
						</thead>
						<tbody >
							<tr v-for="reserva in cata.reservas" v-bind:key="reserva.id">
								<td>
									<p v-if="reserva.cliente">{{reserva.cliente.nombre}} {{reserva.cliente.apellido}}</p>
									<p v-if="!reserva.cliente">{{reserva.cliente_texto}}</p>
								</td>		
								<td>
									<p v-if="reserva.cliente">{{reserva.cliente.country_code}} {{reserva.cliente.telefono}}</p>
									<p v-if="!reserva.cliente">{{reserva.numero}}</p>
								</td>		
								<td>
									<p>{{reserva.adicionales}}</p>							
								</td>	
								<td>
									<p>{{reserva.primera_vez}}</p>							
								</td>						
								<td>
									<p>{{reserva.estatus}}</p>		
								</td>
								<td>
									<p>{{reserva.type}}</p>
								</td>	
								<td>
									<p>{{reserva.notas}}</p>
								</td>						
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed  } from "vue"
	import { useRoute, useRouter } from 'vue-router'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'AdminCatas',
		setup() { 
			let route = useRoute()
			let router = useRouter()

			let catas = ref([])
			let startDate = ref(null)
			let endDate = ref(null)

			let showNewCatas = ref(false)

			let viewOnly = ref(false)

			let week = ref(null)

			let expand = ref(false)

			let view = ref('Catas')
			let reserva_type = ref('todo')

			async function getCatas() {
				const { data } = await supabase
				.from('catas')
				.select('*, ubicacion(id, nombre, capacidad), reservas(*, cliente(nombre, apellido, telefono, country_code, correo))')
				.order('fecha', { ascending: false }) 
				.order('estatus', { referencedTable: 'reservas', ascending: true })
				.order('ubicacion', { ascending: true })

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			}	

			function getDatesBetween(startDate, endDate) {
				let start = new Date(startDate);
				let end = new Date(endDate);

				let dates = [];

				while (start <= end) {

					dates.push(new Date(start));
					start.setDate(start.getDate() + 1);
				}

				return dates;
			}

			async function createCatas(startDate, endDate) {
				if(!confirm("¿Quieres crear catas?")){
					return
				}

				let dates = getDatesBetween(startDate, endDate)				

				let ubicaciones = [3, 4, 5, 13]

				let catasToCreate = dates.map( d => {
					return ubicaciones.map( u => {
						return {
							fecha: d,
							ubicacion: u							
						}
					})
				}).flat()

				const { error } = await supabase.from('catas').insert(catasToCreate);		

				if (error) {
					return alert (error.details)
				}

				useToast().success("Catas creadas", { position: "top-right" });	
				catas.value = await getCatas()
			}	

			function formatDateEuroStyle(fecha) {
				let components = fecha.split("-")

				return `${components[2]}/${components[1]}/${components[0]}`
			}

			function getNextDate(weekday) {
				const date = new Date();

				if (!isFinite(date)) {
					throw new Error('Invalid date input');
				}

				const dayOfWeek = date.getDay();
				const daysUntilNextWeeekday = (weekday - dayOfWeek + 7) % 7 || 7;

				date.setDate(date.getDate() + daysUntilNextWeeekday);

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); 
				const day = String(date.getDate()).padStart(2, '0');

				return `${year}-${month}-${day}`;   
			}

			const weeks = computed( () => {
				return getUniqueValues(catas.value.map( c => {
					let date = new Date(c.fecha)
					return getWeekNumber(date)
				}))
			})

			const catasFiltradas = computed( () => {
				return catas.value.filter( c => {
					let date = new Date(c.fecha)
					let weekCata = getWeekNumber(date)
					if (weekCata == week.value) {
						return true
					} else {
						return false
					}
				}).sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
			})

			const reservas = computed( () => { 
				return catasFiltradas.value.map( c => c.reservas ).flat().filter( r => {
					switch (reserva_type.value) {
					case "publico":
						return r.type == "Publico"						
					case "waitlist":
						return r.estatus == "Waitlist"
					case "primera_vez":
						return r.primera_vez == true	
					case "cancelado":
						return r.estatus == "Waitlist"						
					default:
						return true						
					}
				})
			})

			function getWeekNumber(date) {
				const currentDate = new Date(date.getTime());
				const dayOfWeek = (currentDate.getDay() + 6) % 7;
				currentDate.setDate(currentDate.getDate() - dayOfWeek + 3);
				const firstThursday = new Date(currentDate.getFullYear(), 0, 4);
				const diffDays = (currentDate - firstThursday) / (24 * 60 * 60 * 1000);
				return Math.ceil((diffDays + 1) / 7);
			}

			function getUniqueValues(arrays) {
				const combinedArray = arrays.flat(); // Flatten the arrays into a single array
				const uniqueValues = [...new Set(combinedArray)]; // Use Set to get unique values
				return uniqueValues;
			}

			function goToCata(cataId) {				
				if (event.getModifierState('Meta')) {
					window.open(`/cata/${cataId}/${route.query.view ? "?view=true" : ""}`, '_blank').focus();
				} else {
					router.push(`/cata/${cataId}/${route.query.view ? "?view=true" : ""}`)				
				}
			}

			function toggle() {
				showNewCatas.value = !showNewCatas.value
			}

			onMounted( async () => {  				
				catas.value = await getCatas()
				startDate.value = getNextDate(3)		
				endDate.value = getNextDate(6)

				week.value = weeks.value[0]

				if (route.query.view) {
					viewOnly.value = true
				}
			})	

			function generateCSV() {
				let csv = "Fecha,Lugar,Nombre,Número,Acompañantes,Primera vez,Estatus,Tipo,Notas\n";

				catasFiltradas.value.map( c => c.reservas.map( r => {
					r.fecha = c.fecha
					r.ubicacion = c.ubicacion.nombre
					return r
				})).flat().forEach((r) => {
					csv += [r.fecha, r.ubicacion, r.cliente.correo, r.numero, r.adicionales, r.primera_vez, r.estatus, r.type, `${r.notas.replaceAll(",","")}\n`]
				});

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'reservas.csv';
				anchor.click();
				
			}

			const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

			return {
				catas,
				startDate,
				endDate,
				createCatas,
				goToCata,
				formatDateEuroStyle,
				toggle,
				showNewCatas,
				viewOnly,
				week,
				weeks,
				catasFiltradas,
				daysOfWeek,
				expand,
				view,
				reserva_type,
				reservas,
				generateCSV
			}
		}
	}
</script>