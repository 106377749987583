<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Productos</p>			
			<div class="">
				<button @click="generateCSV()" class="btn btn-primary btn-sm w-full no-animation">CSV</button>
			</div>						
		</div>				

		<div class="flex space-x-4">

			<div class="pt-4 w-full">       
				<select v-model="activo" class="select select-sm select-bordered w-full">
					<option :value="true">Activo</option>
					<option :value="false">Inactivo</option>
					
				</select>
			</div>
		</div>
			

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th>Proveedor</th>		
						<th>Nombre</th>
						<th>Handle</th>					
						<th>Shopify ID</th>
						<th>Costo</th>			
						<th>Precio</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="producto in filteredProductos" v-bind:key="producto.id" @click="goToProducto(producto.id)" class="cursor-pointer">
						<th>{{producto.proveedor ? producto.proveedor.nombre : ""}}</th>		
						<th>{{producto.nombre}}</th>		
						<th>{{producto.shopify_handle}}</th>		
						<th>{{producto.shopify_id}}</th>		
						<th>${{producto.costo}}</th>	
						<th>${{producto.precio}}</th>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>	
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"
	import { useRouter } from 'vue-router' 

	export default {
		name: 'AdminProductos',
		setup() {	
			const productos = ref([])
			const router = useRouter()

			const activo = ref(true)
			
			const filteredProductos = computed( () => {
				return productos.value.filter( p => {
					return p.activo == activo.value
				})
			})

			async function getProductos() {
				const { data } = await supabase
				.from('productos')
				.select('created_at, id, nombre, proveedor (nombre), shopify_id, shopify_handle, precio, costo, activo')							
				.order('nombre', { ascending: true })	

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	
			
			function goToProducto(productoId) {				
				if (event.getModifierState('Meta')) {
					window.open(`/producto/${productoId}`, '_blank').focus();
				} else {
					router.push(`/producto/${productoId}`)				
				}
			}

			onMounted( async () => {					
				productos.value = await getProductos()				
			})

			function generateCSV() {
				let csv = "";

				productos.value.forEach((p) => {
					let a = [p.proveedor ? `\n${ p.proveedor.nombre.replaceAll(",","")}` : "\n",`${p.nombre.replaceAll(",","")}`, p.shopify_handle, p.shopify_id, p.costo, p.precio, p.created_at]					
					csv += a
				});

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'inventario.csv';
				anchor.click();
				
			}

			return {
				activo,
				productos,
				filteredProductos,
				generateCSV,
				goToProducto
			}
		}
	}
</script>