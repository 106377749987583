<template>
	<div class="p-4">
		<div class="flex justify-between">
			<p class="text-2xl">Traspasos</p>


			<div class="flex items-center space-x-4">
				<!-- <div>Min:</div> -->
				<!-- <input class="input input-bordered input-sm w-24" placeholder="Min" type="number" v-model="min"/> -->
				<button @click="generateCSV()" class="btn btn-primary btn-sm no-aniation">CSV</button>
			</div>  
		</div>			

		<div class="pt-4 w-full">       
			<select class="select select-sm select-bordered w-full" v-model="ubicacion">						
				<option selected value="">Todos</option>
				<option v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id" :value="ubicacion"> {{ ubicacion.nombre }} </option>
			</select>
		</div>

		<div class="flex space-x-4 mt-2">
			<input class="input  input-bordered input-sm w-full" v-model="startDate" type="date">

			<input class="input  input-bordered input-sm w-full"  v-model="endDate" type="date">
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table id="table" class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr class="">
						<th>Proveedor</th>
						<th>Producto</th>
						<th v-for="ubicacion in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : false)" v-bind:key="ubicacion.id">{{ubicacion.nombre}}</th>					
						<th v-if="!ubicacion">Oficina</th>							
						<th v-for="ubicacion in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : u.id == ubicacion.id)" v-bind:key="ubicacion.id">{{ubicacion.nombre}}</th>	
						<th v-if="!ubicacion">Total</th>	
					</tr>
				</thead>

				<!-- <thead class="bg-base-100">
					<tr class="">
						<th></th>
						<th></th>
						<th v-for="ubicacion in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : false)" v-bind:key="ubicacion.id"> V / I</th>					
						<th v-if="!ubicacion"></th>							
						<th v-for="ubicacion in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : u.id == ubicacion.id)" v-bind:key="ubicacion.id"></th>	
						<th v-if="!ubicacion"></th>	
					</tr>
				</thead> -->

				<tbody class="">
					<tr v-for="producto in filteredProductos" v-bind:key="producto.id">
						<th>{{producto.proveedor ? producto.proveedor.nombre : ""}}</th>		
						<th>{{producto.nombre}}</th>		
						<td v-for="ubicacion in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : false)" v-bind:key="ubicacion.id"> 
							{{getVentasTotal(producto, ubicacion.id)}} / {{ inventory ? inventory[producto.shopify_handle] ? inventory[producto.shopify_handle][ubicacion.id] || 0 : 0 : ""}}
						</td>						
						<td v-if="!ubicacion">
							{{ inventory ? inventory[producto.shopify_handle] ? inventory[producto.shopify_handle][2] || 0 : 0 : ""}}
						</td>
						<td v-for="u in ubicaciones.filter( u => u.id != 2 ).filter( u =>  ubicacion == '' ? true : u.id == ubicacion.id)"  v-bind:key="u.id">							
							<input v-if="!ubicacion" class="input input-bordered input-sm w-24" placeholder="" type="number" v-model="traspasos[producto.shopify_handle][u.id]"/>
							<div v-if="ubicacion">{{traspasos[producto.shopify_handle][u.id]}}</div>
						</td>
						<td v-if="!ubicacion">
							{{ traspasos[producto.shopify_handle] ? Object.keys(traspasos[producto.shopify_handle]).map( k => traspasos[producto.shopify_handle][k] ?? 0 ).reduce((a, b) => a + b, 0) : 0}}
						</td>					
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"

	export default {
		name: 'AdminVentas',
		setup() {				
			const ubicaciones = ref(['Todos'])
			const productos = ref([])	
			const inventory = ref([])
			const proveedor = ref("")
			const startDate = ref("")
			const endDate = ref("")
			const min = ref(24)

			const ubicacion = ref("")

			const traspasos = ref({})

			//Add mins and maxes por rango to each ubicacion, rango is based on price?
			//Need to add a total column to check when you are sending more than you have

			function getAllocation(producto, ubicacion) {
				if (!inventory.value[producto.shopify_handle]) {
					return 0
				}

				let allocation = getVentasTotal(producto, ubicacion.id) - inventory.value[producto.shopify_handle][ubicacion.id]
				
				if (allocation <= 0) {
					return 
				} 
				
				return allocation
				
				// let allocation;

				// if (producto.price < 500) {
				// 	allocation = 6 - inventory.value[producto.shopify_handle][ubicacion.id]//getGreaterValue(allocation, 6)
				// } else if (producto.price < 700) {
				// 	allocation = 3 - inventory.value[producto.shopify_handle][ubicacion.id]//getGreaterValue(allocation, 4)
				// } else {
				// 	allocation = 2 - inventory.value[producto.shopify_handle][ubicacion.id]//getGreaterValue(allocation, 2)
				// }	

				// if (allocation <= 0) {
				// 	return 
				// } 
				
				// return allocation		
			}


			function getStockActual(producto) {
				if (!inventory.value[producto.shopify_handle]) {
					return 0
				}

				let ids = [4, 2, 3, 5, 13]

				let stock = 0

				ids.forEach( id => {
					stock = stock + inventory.value[producto.shopify_handle][id]
				}) 

				return stock
			}

			function getVentasTotal(producto, ubicacion) {	
				return getSalidas(producto, ubicacion) 
			}

			function getSalidas(producto, ubicacion) {
				let salidas = producto.salidas.filter(a => ubicacion ? a.ubicacion == ubicacion : true)


				if (startDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) > timeShift(startDate.value, 6))
				} 

				if (endDate.value) {
					salidas = salidas.filter(a => getSeconds(a.created_at) < timeShift(endDate.value, 24))
				} 				

				return salidas.length > 0 ? salidas.map(s => s.cantidad).reduce((prev, next) => prev + next) : 0
			}			

			function getSeconds(date) {
				let seconds = new Date(date)
				return seconds.getTime()
			}	

			function timeShift(date, hours) {				
				let beforeShift = new Date(date) 			
				let afterShift = new Date(beforeShift.getTime() + ((6 + hours)*60*60*1000));				
				return afterShift
			}

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 
				.in('id', [4, 2, 3, 5, 13])

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}

			async function getProductos() {
				let query = supabase
				.from('productos')
				.select('id, nombre, shopify_handle, salidas (*, shopify_id), proveedor (id, nombre)')
				.not( "salidas.shopify_id", "is", "null")    
				.order('nombre', { ascending: true })      
				.in('proveedor', ['9', '10'])             			

				const { data } = await query

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	

			function generateCSV() {
				const table = document.getElementById("table");
				let csv = [];

				for (let row of table.rows) {
					let rowData = [];

					for (let cell of row.cells) {
						rowData.push(cell.innerText.replaceAll(",",""));
					}
					csv.push(rowData.join(","));
				}

				const csvContent = csv.join("\n");

				const blob = new Blob([csvContent], { type: 'text/csv' });
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement("a");
				a.setAttribute("href", url);
				a.setAttribute("download", 'extraccion.csv');
				a.style.display = "none";
				document.body.appendChild(a);
				a.click();

				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}

			const filteredProductos = computed( () => {
				return productos.value.filter( producto => {
					if (!producto.shopify_handle) {
						return
					}

					if (!inventory.value[producto.shopify_handle]) {
						return
					}

					return (getStockActual(producto) > 0 || inventory.value[producto.shopify_handle][8] > 0)
				})
			})

			function extraer(producto) {
				if (!producto.shopify_handle) {
					return inventory.value[producto.shopify_handle][2] + inventory.value[producto.shopify_handle][2]
				}

				if (!inventory.value[producto.shopify_handle]) {
					return
				}

				if (getVentasTotal(producto) == 0 && getStockActual(producto) == 0 && inventory.value[producto.shopify_handle][9] > 0) {
					if (min.value > inventory.value[producto.shopify_handle][9]) {
						return inventory.value[producto.shopify_handle][9]
					} else {
						return min.value
					}
				}

				if (getVentasTotal(producto) > getStockActual(producto) && inventory.value[producto.shopify_handle][9] > 0) {
					let toExtraer = getVentasTotal(producto) - getStockActual(producto)

					if (toExtraer > inventory.value[producto.shopify_handle][9]) {
						return inventory.value[producto.shopify_handle][9]
					} else if (toExtraer < min.value) {
						if (min.value > inventory.value[producto.shopify_handle][9]) {
							return inventory.value[producto.shopify_handle][9] 
						} else {
							return min.value
						}
					} else {
						return toExtraer
					}
				}
			}

			onMounted( async () => {	
				startDate.value = getDateDaysAgo(8)
				endDate.value = getDateDaysAgo(1)		

				// getUbicaciones().then( d => {
				// 	ubicaciones.value = d
				// })

				// getProductos().then( d => {
				// 	productos.value = d
				// })

				// getProveedores().then( d => {
				// 	proveedores.value = d
				// })

				// getInventory().then( d => {
				// 	inventory.value = d
				// })			

				ubicaciones.value = await getUbicaciones()							
				productos.value = await getProductos()
				inventory.value = await getInventory()		

				productos.value.forEach( producto => {
					ubicaciones.value.forEach( ubicacion => {

						if (!traspasos.value[producto.shopify_handle]) {
							traspasos.value[producto.shopify_handle] = {}
						}							

						traspasos.value[producto.shopify_handle][ubicacion.id] = getAllocation(producto, ubicacion)
					})
				})	
			})

			

			async function getInventory() {
				const groupedObjects = {};

				const { data } = await supabase.rpc('total_inventory')	

				data.forEach(producto => {					
					if (Object.prototype.hasOwnProperty.call(groupedObjects, producto.shopify_handle)) {						
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock	
						groupedObjects[producto.shopify_handle].total += producto.stock								
					} else {						
						groupedObjects[producto.shopify_handle] = {};
						groupedObjects[producto.shopify_handle][producto.ubicacion] = producto.stock	
						groupedObjects[producto.shopify_handle].total = producto.stock							
					}
				});

				return groupedObjects;
			}	

			function getDateDaysAgo(daysAgo) {
				return new Date(new Date().setDate(new Date().getDate() - daysAgo)).toISOString().split('T')[0];
			}	
			
			return {				
				ubicaciones,
				productos,	
				inventory,			
				getSalidas,
				getVentasTotal,
				getProductos,
				generateCSV,
				proveedor,				
				filteredProductos,
				startDate,
				endDate,
				extraer,
				min,
				getStockActual,
				getAllocation,
				traspasos,
				ubicacion
			}
		}
	}
</script>