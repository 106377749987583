import { createRouter, createWebHistory } from "vue-router";

import Home from "../components/Home.vue";
import EntradaSalida from "../components/Entrada_Salida.vue";
import Inventario from "../components/Inventario.vue";
import Ventas from "../components/Ventas.vue";
import Actualizar_Inventario from "../components/Actualizar_Inventario.vue";
import Transfer from "../components/Transfer.vue";
import Producto from "../components/Producto.vue";
import Proveedor from "../components/Proveedor.vue";
import Revisar_Salidas from "../components/Revisar_Salidas.vue";
import Salidas from "../components/Salidas.vue";
import Pedido from "../components/Pedido.vue";
import Productos from "../components/Productos.vue";
import Transfers from "../components/Transfers.vue";
import Multi_Transfer from "../components/Multi_Transfer.vue";
import Imprimir_Transfer from "../components/Imprimir_Transfer.vue";
import Gracias from "../components/Gracias.vue";
import Duplicados from "../components/Duplicados.vue";
import Entradas from "../components/Entradas.vue";
import Ajustes from "../components/Ajustes.vue";
import Catas from "../components/Catas.vue";
import Cata from "../components/Cata.vue";
import Prospectos from "../components/Prospectos.vue";
import Clubs from "../components/Clubs.vue";
import RutaOnline from "../components/RutaOnline.vue";
import Clientes from "../components/Clientes.vue";
import Cliente from "../components/Cliente.vue";
import Create_Cliente from "../components/Create_Cliente.vue";
import Blast from "../components/Blast.vue";
import Blast_Custom from "../components/Blast_Custom.vue";
import Blasts from "../components/Blasts.vue";
import Cancelaciones from "../components/Cancelaciones.vue";
import Extracciones from "../components/Extracciones.vue";
import Traspasos from "../components/Traspasos.vue";
import Pedidos from "../components/Pedidos.vue";
import Cargos from "../components/Cargos.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },{
      path: "/entrada",
      name: "Entrada",
      component: EntradaSalida,
    },{
      path: "/salida",
      name: "Salida",
      component: EntradaSalida,
    },{
      path: "/ajustes",
      name: "Ajutes",
      component: Ajustes,
    },{
      path: "/inventario",
      name: "Inventario",
      component: Inventario,
    },{
      path: "/ventas",
      name: "Ventas",
      component: Ventas,
    },{
      path: "/actualizar_inventario",
      name: "Actualizar_Inventario",
      component: Actualizar_Inventario,
    },{
      path: "/transfer",
      name: "Transfer",
      component: Transfer,
    },{
      path: "/multi_transfer",
      name: "Multi Transfer",
      component: Multi_Transfer,
    },{
      path: "/producto",
      name: "Agregar Producto",
      component: Producto,
    },{
      path: "/producto/:producto_id",
      name: "Editar Producto",
      component: Producto,
    },{
      path: "/proveedor",
      name: "Agregar Proveedor",
      component: Proveedor,
    },{
      path: "/revisar_salidas",
      name: "Revisar Salidas",
      component: Revisar_Salidas,
    },{
      path: "/salidas",
      name: "Salidas",
      component: Salidas,
    },{
      path: "/productos",
      name: "Productos",
      component: Productos,
    },{
      path: "/transfers",
      name: "Transfers",
      component: Transfers,
    },{
      path: "/transfer/:transfer_id",
      name: "Imprimir_Transfer",
      component: Imprimir_Transfer,
    },{
      path: "/gracias",
      name: "Gracias",
      component: Gracias,
    },{
      path: "/duplicados",
      name: "Duplicados ",
      component: Duplicados,
    },{
      path: "/entradas",
      name: "Entradas",
      component: Entradas,
    },{
      path: "/catas",
      name: "Catas",
      component: Catas,
    },{
      path: "/cata/:cata_id",
      name: "Cata",
      component: Cata,
    },{
      path: "/prospectos",
      name: "Prospectos",
      component: Prospectos,
    },{
      path: "/clubs/",
      name: "Clubs",
      component: Clubs,
    },{
      path: "/clientes/",
      name: "Clientes",
      component: Clientes,
    },{
      path: "/cliente/:id",
      name: "Cliente",
      component: Cliente,
    },{
      path: "/create_cliente",
      name: "Create_Cliente",
      component: Create_Cliente,
    },{
      path: "/blast",
      name: "Blast",
      component: Blast,
    },{
      path: "/blast_custom",
      name: "Blast Custom",
      component: Blast_Custom,
    },{
      path: "/blasts",
      name: "Blasts",
      component: Blasts,
    },{
      path: "/cancelaciones",
      name: "Cancelaciones",
      component: Cancelaciones,
    },{
      path: "/extracciones",
      name: "Extracciones",
      component: Extracciones,
    },{
      path: "/traspasos",
      name: "Traspasos",
      component: Traspasos,
    },{
      path: "/pedidos",
      name: "Pedidos",
      component: Pedidos,
    },{
      path: "/cargos",
      name: "Cargos",
      component: Cargos,
    },{
      path: "/pedido/:pedido_id",
      name: "Pedido",
      component: Pedido,
    },{
      path: "/rutaonline",
      name: "Ruta Online",
      component: RutaOnline,
    }
  ],
});

export default router;
