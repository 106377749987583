<template>
	<div class="p-4">
		<p class="text-2xl">Salidas</p>

		<div class="flex space-x-4">

			<div class="pt-4 w-full">       
				<select v-model="ubicacion" @change="filterSalidas()" class="select select-sm select-bordered w-full">
					<option selected value="todos">Todos</option>
					<option v-for="ubicacion in ubicaciones" v-bind:key="ubicacion.id" :value="ubicacion"> {{ ubicacion.nombre }} </option>
				</select>
			</div>

			<div class="pt-4 w-full">       
				<select v-model="proveedor" @change="filterSalidas()" class="select select-sm select-bordered w-full">
					<option selected value="todos">Todos</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor"> {{ proveedor.nombre }} </option>
				</select>
			</div>
		</div>		

		<div class="flex space-x-4 mt-2">
		<input class="input  input-bordered input-sm w-full" @change="refreshSalidas()" v-model="startDate" type="date">
		<input class="input  input-bordered input-sm w-full"  @change="refreshSalidas()" v-model="endDate" type="date">
		</div>

		<div class="flex w-full pt-4 overflow-x-scroll">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">
					<tr>
						<th>Fecha</th>		
						<th>Ubicación</th>
						<th>Shopify ID</th>
						<th>Producto</th>					
						<th>Cantidad</th>
						<th>Reference</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="salida in salidas" v-bind:key="salida.id">
						<th>{{salida.created_at.split("T")[0]}}</th>		
						<th>{{salida.ubicacion.nombre}}</th>		
						<th>{{salida.shopify_id}}</th>
						<th>{{salida.producto ? salida.producto.nombre : ""}}</th>		
						<th>{{salida.cantidad}}</th>		
						<th>{{salida.reference}}</th>							
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"

	export default {
		name: 'AdminSalidas',
		setup() {	
			const salidas = ref([])
			const proveedores = ref([])
			const proveedor = ref('todos')
			const ubicaciones = ref([])
			const ubicacion = ref('todos')

			const startDate = ref("")
			const endDate = ref("")

			async function getProveedores() {
				const { data } = await supabase
				.from('proveedores')
				.select('id, nombre')
				.order('nombre', { ascending: true }) 

				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			} 

			async function getUbicaciones() {
				const { data } = await supabase
				.from('ubicaciones')
				.select('id, nombre')
				.eq("activo", true)
				.order('order', { ascending: true }) 

				if (data) {					
					return data
				} else {
					alert("No data returned")
				}
			}

			async function filterSalidas() {
				salidas.value = (await getSalidas()).filter( s => {
					if (ubicacion.value == "todos") {
						return true
					}

					return s.ubicacion.id == ubicacion.value.id
				}).filter( s => {
					if (proveedor.value == "todos") {
						return true
					}

					if (!s.producto) {
						return false 
					}

					if (proveedor.value) {
						return s.producto.proveedor && s.producto.proveedor == proveedor.value.id
					} 

					return true
				})
			}

			async function getSalidas() {								
				const { data, error } = await supabase
				.from('salidas')
				.select('*, producto(nombre, proveedor), ubicacion(id, nombre)')			
				.order('created_at', { ascending: false })	
				.filter('created_at', 'gte', startDate.value)
				.filter('created_at', 'lte', endDate.value)

				console.log(data, error)

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}			
			
			function refreshSalidas() {
				getSalidas().then( data => {
					salidas.value = data
				})	
			}
			onMounted( async () => {	
				getProveedores().then( data => {
					proveedores.value = data
				})				

				const today = new Date();
				endDate.value = today.toISOString().split('T')[0]
				today.setMonth(today.getMonth() - 1);
				startDate.value = today.toISOString().split('T')[0]

				getSalidas().then( data => {
					salidas.value = data
				})					
				getUbicaciones().then( data => {
					ubicaciones.value = data
				})			
			})

			return {
				salidas,
				proveedores,
				proveedor,
				refreshSalidas,
				filterSalidas,
				ubicaciones,
				ubicacion,
				startDate,
				endDate,
				getSalidas
			}
		}
	}
</script>