<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Pedido {{route.params.pedido_id}}</p>	


		</div>

		<div class="pt-4 space-y-4" v-if="pedido.cliente">
			<div class="flex flex-row w-full space-x-4">
				<div class="w-full">
					<p class="text-sm pb-2">Nombre</p>
					{{pedido.cliente.nombre}} {{pedido.cliente.apellido}}
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Correo</p>
					{{pedido.cliente.correo}}
				</div>

				<div class="w-full">
					<p class="text-sm pb-2">Ubicación</p>
					{{pedido.ubicacion.nombre}}
				</div>
			</div>

		</div>
		<div class="flex flex-row space-x-4">
			
			<div class="pt-4 w-full">      
				<p class="text-xl mt-4">Productos</p>	
				<table class="table table-xs mt-4">
					<thead class="sticky bg-base-100 top-0">
						<tr>
							<th class="w-1/3">Producto</th>		
							<th class="w-1/3">Cantidada</th>
							<th class="w-1/3">Total</th>																			

						</tr>
					</thead>
					<tbody v-for="pedido_detalle in pedido.pedido_detalles" v-bind:key="pedido_detalle.id">
						<tr>
							<td>{{pedido_detalle.producto.nombre}}</td>		
							<td>{{pedido_detalle.cantidad}}</td>		

							<td>${{pedido_detalle.total}}</td>																		
						</tr>									
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useRoute } from 'vue-router'
	// import axios from 'axios'
	// import { useToast } from 'vue-toast-notification';
	// import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminProducto',
		setup() { 
			const route = useRoute()
			
			const pedido = ref({})

			async function getPedido() {
				const { data } = await supabase
				.from('pedidos')
				.select('*, cliente(id, nombre, apellido, correo), ubicacion(id, nombre), pedido_detalles(cantidad, producto(nombre), total)')
				.eq('id', route.params.pedido_id) 
				.single()

				console.log(data)
				if (data) {
					return data
				} else {
					alert("No data returned")
				}
			} 

			
			onMounted( async () => {  
				pedido.value = await getPedido()				
			})		

			return {
				route,
				pedido
			}
		}
	}
</script>