<template>
	<div class="p-4">
		<div class="flex flex-row justify-between">
			<p class="text-2xl">Revisar Salidas</p>
			<button @click="deleteSalidas()" class="btn btn-error btn-sm no-animation">Eliminar</button>
		</div>
		<div class="flex w-full pt-4">
			<table class="table table-xs">
				<thead class="sticky bg-base-100 top-0">				
					<tr>
						<th></th>
						<th>Fecha</th>
						<th>Shopify ID</th>
						<th>Reference</th>			
						<th>Cantidad</th>	
						<th></th>							
						<th></th>						
						<th></th>						
				
						<th></th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="(salida, i) in salidas" v-bind:key="salida.id">
						<td>
							<input class="checkbox no-animation" type="checkbox" v-model="toDelete[salida.id]"/>
						</td>
						<td>							
							{{salida.created_at.split("T")[0]}}
						</td>
						<td>
							{{salida.shopify_id}}
						</td>
						<td>
							{{salida.reference}}
						</td>
						<td>
							{{salida.cantidad}}
						</td>		
						<td>
							<p v-for="producto in salida.productos" v-bind:key="producto.id">{{producto.nombre}}</p>
						</td>
						<td>
							<select v-model="salida.producto" class="select select-sm select-bordered w-full">							
								<option selected disabled value="">Escoger un producto</option>
								<option v-for="producto in productos" v-bind:key="producto.id" :value="producto"> {{ producto.nombre }} </option>
							</select>
						</td>		
						<td>
							<button @click="actualizarSalida(salida)" class="btn btn-primary btn-sm no-animation">Actualizar</button>
						</td>	
			
						<td>
							<button @click="duplicateSalida(salida, i)" class="btn btn-warning btn-sm no-animation">Duplicar</button>
						</td>		
					</tr>
				</tbody>
			</table>
		</div>	
	</div>
</template>

<script>	
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'AdminRevisarSalidas',
		setup() {	
			const productos = ref([])
			const salidas = ref([])

			const toDelete = ref({})

			const loading = useLoading({
				loader: "spinner"
			});

			async function getSalidas() {
				const { data } = await supabase
				.from('salidas')
				.select('*')
				.is('producto', null)								
				.order('id', { ascending: false })

				if (data) {
					return data
				} else {
					alert ("No data returned")
				}
			}

			async function getProductos() {
				const { data } = await supabase
				.from('productos')				
				.select('id, nombre, proveedor (nombre)')								
				.eq("activo", true)

				if (data) {
					return data.sort((a,b) => ( (a.proveedor ? a.proveedor.nombre : "") > (b.proveedor ? b.proveedor.nombre : "") ) ? 1 : (( (b.proveedor ? b.proveedor.nombre : "") > (a.proveedor ? a.proveedor.nombre : "") ) ? -1 : 0))
				} else {
					alert ("No data returned")
				}
			}	
			
			async function deleteSalidas() {
				if(confirm("¿Quieres borrar estas salidas?")){

					let loader = loading.show()
					let salidasIds = Object.keys(toDelete.value) 

					console.log(salidasIds)

					salidasIds = salidasIds.filter( s => {
						return toDelete.value[s] 
					})

					if (salidasIds.length > 0) {
						await supabase
						.from('salidas')						
						.delete()
						.in('id', salidasIds)

						salidas.value = await getSalidas()
						loader.hide()				
					}


					// const index = salidas.value.indexOf(salida);
					// if (index > -1) {
					// 	salidas.value.splice(index, 1);
					// }
				}
			}

			function duplicateSalida(salida, i) {
				let newSalida = Object.assign({}, salida)
				console.log(newSalida)
				delete newSalida.id
				delete newSalida.producto

				salidas.value.splice(i + 1, 0, newSalida);
			}

			async function actualizarSalida(salida) {
				if (!salida.producto) {
					return alert('Escoger un producto')
				}

				if(confirm("¿Quieres actualizar esta salida?")){
					let s = {
						shopify_id: salida.shopify_id,
						reference: null,
						producto: salida.producto.id,
						ubicacion: salida.ubicacion,
						cantidad: salida.cantidad
					}

					if (salida.id) {
						s.id = salida.id
					} else {
						s.created_at = salida.created_at
					}

					let { error } =  await supabase.from('salidas').upsert(s)

					if (error) {
						return alert(error.details)
					}
					
					const index = salidas.value.indexOf(salida);
					if (index > -1) {
						salidas.value.splice(index, 1);
					}
				}
			}

			onMounted( async () => {					
				salidas.value = await getSalidas()
				productos.value = await getProductos()
			})

			return {
				toDelete,
				salidas,
				productos,
				deleteSalidas,
				duplicateSalida,
				actualizarSalida			
			}
		}
	}
</script>